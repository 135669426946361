import { ListItemButton, ListItemIcon, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import colorConfigs from '../../configs/colorConfigs'
import { RootState } from '../../redux/store'
import { RouteType } from '../../routes/config'

type Props = {
  item: RouteType
  drawerExpanded: boolean;
}

const SidebarItem = ({ item, drawerExpanded }: Props) => {
  const { appState } = useSelector((state: RootState) => state.appState)

  return item.sidebarProps && item.path ? (
    <ListItemButton
      component={Link}
      to={item.path}
      className='newsan-sidebar-item'
      sx={{
        '&: hover': {
          // color: colorConfigs.sidebar.hoverColor,
          fontWeight: 'bold',
          backgroundColor: 'unset',
          '& .MuiListItemIcon-root' : {
            // color: colorConfigs.sidebar.hoverColor,
            fontWeight: 'bold',
            backgroundColor: 'unset',
          },
        },
        color: appState === item.state ? colorConfigs.sidebar.hoverColor : 'unset',
        fontWeight: appState === item.state ? 'bold' : 'unset',
        paddingY: '12px',
        paddingX: '24px',
      }}
    >
      <Tooltip title={!drawerExpanded ? item.sidebarProps.displayText : ''}>
        <ListItemIcon
        className={
            appState === item.state ? 'menu-active' : 'menu-inactive'}
          sx={{
            minWidth: '35px'
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
      </Tooltip>
      {drawerExpanded ? item.sidebarProps.displayText : ''}
    </ListItemButton>
  ) : null
}

export default SidebarItem
