/* eslint-disable @typescript-eslint/no-explicit-any */
import { SvgIcon } from '@mui/material';
import {ReactComponent as Character} from '../../assets/images/character.svg';
import {ReactComponent as CharacterRate} from '../../assets/images/rate-character.svg';


class NewsanPics {
    public static  CHARACTER: any = <SvgIcon component={Character} viewBox="0 0 127 238" />; 
    public static  CHARACTER_RATE_SCREEN: any = <SvgIcon component={CharacterRate} viewBox="0 0 178 197" />; 


}



export default NewsanPics;
