/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
// import useData from '../../components/hooks/useData'
import Topbar from '../../components/common/topbar/Topbar'
import { ErrorTransaction, FilterTable, FilterTableType, TableDetailCell, TableHeaderCell } from '../../model/models-module'
import { addParam } from '../../utils/CommonUtils'
import {useData} from '../../components/hooks/useData'
import DataTable from '../../components/common/datatable/Datatable'
import { CircularProgress, Tooltip, Typography} from '@mui/material'
import TextWithPopover from '../../components/common/text-with-popOver/TextWithPopover'
import ButtonToCopy from '../../components/common/ButtonToCopy'
import classes from './ErrorsTransactionReport.module.css'
import { GridDTO } from '../../model/dto-module'


// const gridActions = 
const ErrorsTransactionReport = () => {
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([]);
  const [dataTo, setDataTo] = useState<number>(50);
  const [countItems, setCountItems] = useState<number>(0);
  const [dataOffset, setDataOffset] = useState<number>(0);
  const [user, setUser] = useState<string>();
  const [transactionId, setTransactionId] = useState<string>();
  const [type, setType] = useState<string>();
  const [transactionData, setTransactionData] = useState<string>();
  const [transactionError, setTransactionError] = useState<string>();

  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]);
  const [page, setPage] = useState<number>(1);

  const [baseEndpoint, setBaseEndpoint] = useState<string>('/transaction-errors');  
  const [endpoint, setEndPoint] = useState<string>('');  
  const [params] = useState<Map<string,string>>(new Map());  
  const [loading, error, dataGrid] = useData<GridDTO<ErrorTransaction>>(endpoint, 'GET');
  const [loadingTypes, errorTypes, dataTypes] = useData<string[]>('transaction-types', 'GET');

  const formatearFecha = (date: string) => {
    const fechaObj = new Date(date);
    
    const año = fechaObj.getFullYear();
    const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
    const dia = fechaObj.getDate().toString().padStart(2, '0');
    const hora = fechaObj.getHours().toString().padStart(2, '0');
    const minutos = fechaObj.getMinutes().toString().padStart(2, '0');
    
    return `${dia}/${mes}/${año} ${hora}:${minutos}`;
  }

  if(error || errorTypes ) {
    console.error(error, errorTypes);
  }

  useEffect(()=> {
    if (baseEndpoint == '') setBaseEndpoint('/transaction-errors')
  }, [baseEndpoint])

  useEffect(()=> {
      addParam(dataTo, 'limit', params, baseEndpoint, setEndPoint);
  }, [dataTo, baseEndpoint])

  useEffect(()=> {
    
      addParam(dataOffset, 'offset', params, baseEndpoint, setEndPoint);
    
  }, [dataOffset, baseEndpoint])

  useEffect(()=> { 
      setDataOffset(0)
      addParam(user, 'creator', params, baseEndpoint, setEndPoint);    
  }, [user, baseEndpoint]);

  useEffect(()=> {
      setDataOffset(0)
      addParam(transactionId, 'transactionId', params, baseEndpoint, setEndPoint);
    
  }, [transactionId, baseEndpoint]);

  useEffect(()=> {
      setDataOffset(0)
      addParam(type, 'transactionType', params, baseEndpoint, setEndPoint);
  }, [type, baseEndpoint]);

  useEffect(()=> {
    setDataOffset(0)
    addParam(transactionData, 'transactionData', params, baseEndpoint, setEndPoint);
  }, [transactionData, baseEndpoint]);

  useEffect(()=> {
    setDataOffset(0)
    addParam(transactionError, 'error', params, baseEndpoint, setEndPoint);
  }, [transactionError, baseEndpoint]);

  useEffect(()=> {
    let filterLoadeed = !loading && dataGrid != null && dataHeader.length === 0;
    filterLoadeed = filterLoadeed && !loadingTypes && dataTypes != null 

    if(filterLoadeed ) {
      setDataHeader([
        new TableHeaderCell({label: 'Fecha', key: 'created', sorteable: false, colStyle: {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '120px'}}),
        new TableHeaderCell({label: 'Usuario', key: 'usuario', sorteable: false, filter: new FilterTable({onChange: (newVal: any)=>{ setUser(newVal)}, placeholder: 'Busca aqui...'  }), colStyle: {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}),
        new TableHeaderCell({label: 'Transacción', key: 'transactionId', sorteable: false, filter: new FilterTable({onChange: (newVal: any)=>{ setTransactionId(newVal)}, placeholder: 'Busca aqui...'  }),  headerStyle:{minWidth: '140px'}, colStyle: {maxWidth: '140px'}}), 
        new TableHeaderCell({label: 'Tipo', key: 'transactionType', sorteable: false, filter: new FilterTable({onChange:  (newVal: any)=>{ setType(newVal)}, useCodeField:true, data: dataTypes! ? dataTypes.map((rt, i)=>{
          return {id: i+1, code: rt, description: rt};
        }) : [], type: FilterTableType.MULTIPLE})}),
        new TableHeaderCell({label: 'Datos', key: 'transactionData', sorteable: false, filter: new FilterTable({onChange: (newVal: any)=>{ setTransactionData(newVal)}, placeholder: 'Busca aqui...'  })}),
        new TableHeaderCell({label: 'Detalle error', key: 'error', sorteable: false, filter: new FilterTable({onChange: (newVal: any)=>{ setTransactionError(newVal)}, placeholder: 'Busca aqui...'  })}),
      ])  
    }
  })

  

  useEffect(()=>{
    if(!loading && dataGrid) {
      setCountItems(dataGrid.count)
      dataGrid.results.sort((a, b) => {
        const fechaA = new Date(a.created).getTime();
        const fechaB = new Date(b.created).getTime();
        return fechaB - fechaA;
      });
      setDataShow(dataGrid.results.map((r) => {
        return new TableDetailCell({...r, created: formatearFecha(r.created)}, [],
          new Map<string, React.ReactNode>([
            ['transactionData',
            <div key={r.transactionData + 'transactionData'} className={classes['textWithCopy']} style={{bottom: '10px'}}>
            <TextWithPopover key={'transactionData'} text={'Ver detalle'} popoverText={r.transactionData}></TextWithPopover>
            <ButtonToCopy text={r.transactionData} style={{position: 'relative', right: '-5px', top: '-8px'}}></ButtonToCopy>
            </div>
            ],
            ['error',
            <div key={r.error + 'error'} className={classes['textWithCopy']} style={{bottom: '10px'}}>
            <TextWithPopover key={'error'} text={'Ver detalle'} popoverText={r.error}></TextWithPopover>
            <ButtonToCopy text={r.error} style={{position: 'relative', right: '-8px', top: '-8px'}}></ButtonToCopy>
            </div>
            ],
            ['transactionId',
            <div key={r.transactionId + 'transaction-id'} className={classes['textWithCopy']}>
              {r.transactionId.length < 11 ? <Typography fontSize={'0.875rem'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>{r.transactionId}</Typography> : <Tooltip title={r.transactionId}><Typography  whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'} fontSize={'0.875rem'} maxWidth={93}>{r.transactionId}</Typography></Tooltip>}
              <ButtonToCopy text={r.transactionId} style={{position: 'relative', right: '0px', top: '-10px'}}></ButtonToCopy>
            </div>
            ]
        ]),
        classes['customRow']
          )
      }))
    }
  }, [setDataShow, dataGrid, loading]);

  function reloadTable() {
    setBaseEndpoint('')
    setEndPoint('')
  }

  return (
    <div className={classes.errorTransactionPage}>
    {
      loading ?
      <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
      : null
    }
      <Topbar title={'Monitor de errores de transacciones'} hasReloadIcon reloadPage={reloadTable} />
    <div className={classes['container-abm-page']}>
        <div className={classes['table-templates-container']}>
          <DataTable 
           headers={dataHeader}
           rows={dataShow}
           countItems={countItems}
           rowsPerPage={dataTo}
           hasActions={false}
           appendOnScrollToBottom={false}
           onPageSizeChange={(size) => {setDataTo(size); setDataOffset(0); setPage(1);}}
           onPageChange={(page: number)=>{ setPage(page); setDataOffset(page-1) }}
           pageSelected={page}
           pageable={true}
           selectable={false}
          >
          </DataTable>
      </div>
    </div>
    </div>
  )
}

export default ErrorsTransactionReport;
