/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CustomWizard, { StepComponent } from '../../../components/common/wizard/Wizard';
import NewReserveStepOne from './steps/stepOne';
import { CreateReservedStockDto, NewReserveDTO } from '../../../model/dto-module';
import NewReserveStepTwo from './steps/stepTwo';
import NewReserveStepThree from './steps/stepThree';
import { CodeDescription, WarehouseReservesTypeDistribution, WarehouseStockMovement } from '../../../model/models-module';
import DistrubutionService from '../../../services/DistributionService';
import classes from './NewReservation.module.css'
import ReserveTypeService from '../../../services/ReserveTypeService';
import WarehouseStockMovementService from '../../../services/WarehouseStockMovementService';
import StockService from '../../../services/StockService';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface NewReservationProps {
    open: boolean;
    onClose: (e?: any, reason?: string) => void;
    sku?:string;
    showSkuDesc?: boolean;
    fixedSku?: boolean;
    data?: any;
    success?: boolean;
    error?: boolean;
    editMode?: boolean;
    successMessage?: string | null;
    errorMessage?: string | null;
  }

const NewReservation = ({ open, onClose, fixedSku = false, sku, showSkuDesc, data, editMode = false }: NewReservationProps) => {
  const [steps, setSteps] = useState<StepComponent[]>([]);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [value, setValue] = useState<NewReserveDTO>(new NewReserveDTO(data));
  const [distributionList, setDistributionList] = useState<WarehouseReservesTypeDistribution[]>([]);
  const reseveTypeServ = ReserveTypeService.Instance;
  const distributionServ = DistrubutionService.Instance;
  const warehouseStockMovementServ = WarehouseStockMovementService.Instance;
  const [distributionCentres, setDistributionCentres] = useState<CodeDescription[]>([]);
  const [reserveTypes, setReserveTypes] = useState<CodeDescription[]>([]);
  const [stockMovements, setStockMovements] = useState<WarehouseStockMovement[]>([]);
  const [service] = useState(StockService.Instance);
  const [isEdit] = useState(editMode);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  useMemo(()=>{
    if(sku! && sku != value.productCode) {
      value.productCode = sku;
    }
  }, [sku]);

  useMemo(async () => {
    setDistributionCentres(await distributionServ.findAllDistributionCentres());
    setReserveTypes((await reseveTypeServ.findAll()).results);
  }, []);

  const submitStep1 = useCallback((values: NewReserveDTO)=>{

        values.reserveType = reserveTypes.find((rt)=>rt.code === values.reserveTypeCode);

        setShowSpinner(true);
        try {
          setValue(Object.assign(value, values)); 
          if(isEdit) {
            const params = {
              idWarehouse : values.warehouseId,
              idReserveType : values.reserveType!.id,
              sku : values.productCode,
              distributionCentreCode : values.distributionCentreCode
            }
            warehouseStockMovementServ.findPendings(params).then((resp)=>{
              setValue(Object.assign(value, values)); 
              setStockMovements(resp);
              setActiveStep(activeStep + 1);
            }).catch(e=>console.error(e))
            .finally(()=>{
              setShowSpinner(false);
            });
          } else {
            distributionServ.findConfiguration(values).then(list=>{
              setDistributionList(list);
              setActiveStep(activeStep + 1);
            }).catch(e=>console.error(e))
            .finally(()=>{
              setShowSpinner(false);
            });
          }
        } catch(e) {
          console.error(e);
        } finally {
          setShowSpinner(false);
        }
  }, [activeStep, isEdit, reserveTypes]);
  
  const submitStep2 = useCallback((values: NewReserveDTO)=>{
        setShowSpinner(true);
        const params = {
          idWarehouse : values.warehouseId,
          idReserveType : values.reserveType!.id,
          sku : values.productCode,
          distributionCentreCode : values.distributionCentreCode
        }
        warehouseStockMovementServ.findPendings(params).then((resp)=>{
        setValue(Object.assign(value, values)); 
        setStockMovements(resp);
        setActiveStep(activeStep + 1);
      }).catch(e=>console.error(e))
      .finally(()=>{
        setShowSpinner(false);
      });
  }, [activeStep, isEdit]);

  const submitStep3 = useCallback((values: NewReserveDTO)=>{
    setShowSpinner(true);
    setValue(Object.assign(value, values)); 
    if(isEdit) {
      const body = new CreateReservedStockDto(values);
      console.warn(body);
      service.updateStock({id: data.id, data: body}).then(()=>{
        onClose(undefined, 'confirm');
      }).catch(e=>console.error(e))
      .finally(()=>{
        setShowSpinner(false);
      });
    } else {
      const body = new CreateReservedStockDto(values);
      console.warn(body);
      service.createStock(body).then(()=>{
        onClose(undefined, 'confirm');
      }).catch(e=>{
        console.error(e);
      })
      .finally(()=>{
        setShowSpinner(false);
      });
    }
    
  }, [activeStep, isEdit]);

  useEffect(()=>{
    const auxSteps = [
    {label: 'Datos', component: <NewReserveStepOne fixedSKU={fixedSku}
    isEdit={isEdit} onAfterBack={()=>onClose()} showSkuDesc={showSkuDesc} onAfterSubmit= {submitStep1} value={value} distributionCentres={distributionCentres} reserveTypes={reserveTypes}/>},
  ];
  if(!isEdit) {
    auxSteps.push(
      {label: 'Distribución de Stock', component: <NewReserveStepTwo list={distributionList} onAfterBack={()=>setActiveStep(activeStep - 1)} onAfterSubmit= {submitStep2} value={value} />});
  }
  auxSteps.push(
    {label: 'Ventas pendientes', component: <NewReserveStepThree sales={stockMovements} onAfterBack={()=>setActiveStep(activeStep - 1)} onAfterSubmit= {submitStep3} value={value} />});

    setSteps(auxSteps);

  }, [ distributionCentres, reserveTypes, stockMovements, distributionList, activeStep, value, submitStep1]);

    return (
      <Dialog open={open} onClose={onClose} className={'dialog-md'} maxWidth='md'>

        {
        showSpinner ?
          <div className='loadingPage'>
            <CircularProgress className='circleProgress'/>
          </div>
          : null
        }

        <IconButton onClick={onClose} className={classes.closeIconBtn}>
            <CloseIcon className={classes.closeIconBtn} />
        </IconButton>
        <DialogContent className='nwsn-scroll'>
           <CustomWizard steps={steps} activeStep={activeStep} title='' cssClass={`${classes.stepFull}`}>
            {
              steps.length > 0 ?
              steps.at(activeStep)!.component
              : null
            }
          </CustomWizard>
        </DialogContent>
      </Dialog>
    );
  };
  
  export default NewReservation;
