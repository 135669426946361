/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Step, StepIcon, StepLabel, Stepper } from '@mui/material';
import classes from './Wizard.module.css';
import { useNavigate } from 'react-router-dom';


type WizardProps = {
    title: string;
    subtitle?: string;
    icon?: any;
    iconClass?: any;
    activeStep: number;
    steps: StepComponent[];
    children?: any;
    cssClass?: string;
}

export type StepComponent = {
  label: string;
  component: any;
}

const CustomWizard = ({children ,title ,subtitle ,icon ,activeStep, steps, iconClass, cssClass}: WizardProps) => {
  const navigate = useNavigate();


    return (
  <div className={classes.backgroundImage}>
    <div>
      <div className={classes.imageContainer}>
        <span className={'newsan-logo ' + classes.logoSize} onClick={()=>navigate('/home')}></span>
      </div>

      <div className={classes.stepperLegendMobile}> 
        {subtitle}
      </div>

      {
        title ? 
          <div className={classes.headerStepper}>
              <div className={`${classes.stepTitle}`}>
                {
                  icon ?
                  <span className={`${classes.iconTitle} ${iconClass ?? ''}`}>{icon}</span>
                  : null
                }
                <span className={classes.title}>{title}</span>
              </div>
          </div>
        : null
      }
      
      {
        subtitle ?
        <div className={classes.stepperLegend}> 
          {subtitle}
        </div>
        : null

      }
      <div className={`${classes.stepperContainer} ${cssClass}`}>
            <span className={'floatingImg'}/>
            <Box className={classes.stepperContainerForm}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((step, index) => (
                  <Step key={`${step.label}`} className={classes.stepperStep}>
                    <StepLabel 
                    className={`${classes.labelStep}  ${(index <= activeStep ? classes.active : '')}`}
                    StepIconComponent={(props: any) => (
                      <StepIcon
                        {...props}
                        icon={props.icon}
                        active={props.active || props.completed}
                        completed={false}
                        className={props.completed ? classes.stepComplete : (props.active ? classes.stepActive : '')}
                      />
                    )} 
                    > <span className={classes.labelStepText}>{step.label}</span></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            {children}

      </div>
    </div>
  </div>)
}


export default CustomWizard;