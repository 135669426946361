/* eslint-disable @typescript-eslint/no-explicit-any */
import { Role, User } from '../model/dto-module';
import BaseService from './BaseService';

export default class RoleService extends BaseService {


    private static _instance: RoleService;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    async findAll(): Promise<Role[]> {
        
        try {    
            const response = await this.client({
                url: '/auth/roles',
                method: 'GET'
            });

            return response.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
        
    }

    async findUsersByRole(role:string): Promise<User[]>{
        
        try {
            const response = await this.client({
                url: `/auth/roles/${role}`,
                method: 'GET'
            });

            return response.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }

    }
    


}