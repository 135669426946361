/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService from './BaseService';

export default class ReserveTypeService extends BaseService {


    private static _instance: ReserveTypeService;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    async findAll(type?: string) {
        
        try {    
            let params = '';
            if(type!) {
                params += `&type=${type}`;
            }
            // Envío 0 para que le back no tenga en cuenta el valor del limit y devuelva toda la tabla
            const response = await this.client({
                url: `/reserved-types?limit=0${params}`,
                method: 'GET'
            });

            return response.data.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
        
    }    

    async findAllTypes() {
        
        try {    
            // Envío 0 para que le back no tenga en cuenta el valor del limit y devuelva toda la tabla
            const response = await this.client({
                url: '/reserved-types/types',
                method: 'GET'
            });

            return response.data.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
        
    }    


}