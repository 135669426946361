/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import './DragAndDrop.css'
import { Button } from '@mui/material';
import NewsanIcons from '../Icons';
import axios, { AxiosError } from 'axios';
import { IErrorResponse } from '../../hooks/useData';
import UserService from '../../../services/UserService';

const baseURL: string | undefined = process.env.REACT_APP_API_BASE_URL;

type Props = {
  url:string;
  onChange?: (response: any)=>void;
}

// drag drop file component
const DragDropFile = ({url, onChange}: Props) => {

  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  const handleFiles = async (file: File) => {
    try {
      const client = axios.create({baseURL, headers: {Authorization : `Bearer ${UserService.getToken()}`}});
      const form = new FormData();
      form.append('file', file);
      await client({
          url: url,
          method: 'POST',
          data: form
        });
        if(onChange) {
          onChange('');
        }

    } catch (err: any) {
        const error = err as Error | AxiosError<IErrorResponse>
        if (axios.isAxiosError(error)) {
          if (error.code === AxiosError.ERR_CANCELED) {
            return
          }
          console.error(error?.response?.data?.message || error.message)
        } else {
          console.error(error.message)
        }
      }
  }
  
  // handle drag events
  const handleDrag = function(e: React.DragEvent<HTMLElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  
  // triggers when file is dropped
  const handleDrop = function(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFiles(e.dataTransfer.files[0]);
    }
  };
  
  // triggers when file is selected with click
  const handleChange = function(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFiles(e.target.files[0]);
    }
  };
  
// triggers the input when the button is clicked
  const onButtonClick = () => {
    if(inputRef) {
        (inputRef.current! as HTMLInputElement).click();
        // inputRef!.current.click();
    }
  };
  
  return (
    <form id='form-file-upload' onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
      <input ref={inputRef} type='file' id='input-file-upload' accept=".xlsx" multiple={false} onChange={handleChange} />
      <label id='label-file-upload' htmlFor='input-file-upload' className={dragActive ? 'drag-active' : '' }>
        <div>
            <span className='icon-add'>
                {NewsanIcons.ADD_FILE}
            </span>
          <p className='drop-legend'>Arrastre y suelte aquí su archivo</p>
          <p className='or-legend'>- O -</p>
          <Button variant="contained" className='button-newsan-red' onClick={onButtonClick}>
            Subir Manual
          </Button>
        </div> 
      </label>
      { dragActive && <div id='drag-file-element' onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </form>
  );
};



export default DragDropFile;