/* eslint-disable @typescript-eslint/no-explicit-any */
import { SvgIcon } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import CloseIcon from '@mui/icons-material/Close';

type MaskOptions = {
  prefix: string;
  suffix: string;
  includeThousandsSeparator: boolean;
  thousandsSeparatorSymbol: string;
  allowDecimal: boolean;
  decimalSymbol: string;
  decimalLimit: number;
  integerLimit: number;
  allowNegative: boolean;
  allowLeadingZeroes: boolean;
}

type Props = {
    value?: any;
    type?: string;
    placeholder?: string;
    maskOptions?: MaskOptions;
    cssClass?: string;
    cssStyle?: any;
    debounceTime?:number;
    inputDisabled?: boolean
    min?: number;
    max?: number;
    showClear?: boolean;
    onChange(newVal?:string):void;
    onBlur?(val?:string):void;
    onEnter?(val?:string):void;
    onFocus?(val?:string):void;
}

const defaultMaskOptions: MaskOptions  = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 9, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
}

const generateInput = (inputValue: any, handleInputChange: (event: any) => void,  {type, placeholder,maskOptions=defaultMaskOptions, cssClass='', cssStyle, inputDisabled = false, onBlur, onEnter, onFocus, ...props } : Props) => {  
  switch(type){
    case 'currency': 
    return <div className={`input-currency-container ${cssClass}`}>
            <MaskedInput onKeyDown={(e) => {
    if (e.key === 'Tab') {
      e.stopPropagation();
    }
  }} className='input-currency-field' mask={createNumberMask(maskOptions)} value={inputValue} onChange={handleInputChange} placeholder={placeholder}></MaskedInput>
            <i></i>
            </div>
    case 'number':


    return (
      <input
        disabled = {inputDisabled}
        className={cssClass}
        min={props?.min}
        max={props?.max}
        onFocus={(event) => {
          event.target.select()
          if (onFocus) {
            onFocus(inputValue)
          }
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur(inputValue)
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            e.stopPropagation()
          }

          if (e.key.length === 1 && /[a-z|A-Z]/.test(e.key)) {
            e.preventDefault()
            e.stopPropagation()
            return
          }
          if (onEnter && e.key.toLocaleLowerCase() === 'enter') {
            onEnter(inputValue)
          }
        }}
        style={cssStyle}
        type={type}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
      />
    )
    default:
    return <input onKeyDown={(e) => {
    if (e.key === 'Tab') {
      e.stopPropagation();
    }
  }} className={cssClass} disabled={inputDisabled} type={type} placeholder={placeholder} value={inputValue} onChange={handleInputChange} />
  }

}

const unformattedValue = ({type, inputValue, maskOptions = defaultMaskOptions} : {type: string, inputValue: string, maskOptions?: MaskOptions} ) => {

        switch(type) {
          case 'currency': 
            return inputValue  ? String(inputValue).replaceAll(maskOptions!.thousandsSeparatorSymbol, '').replace(maskOptions!.decimalSymbol, '.') : null;
          default:
            return inputValue;
        }
}

const InputFilter = (props: Props)=> {

    const [inputValue, setInputValue] = useState('');
    const [debouncedInputValue, setDebouncedInputValue] = useState<string>(props.value);

    const handleInputChange = (event: any) => {
        switch (props.type) {
          case 'date':
            if (event.target.value) {
              setInputValue(moment(event.target.value).format('yyyy-MM-DD'))
            } else {
              setInputValue(event.target.value)
            }
            break
          case 'number': {
            const { value, min, max } = event.target
            if (min && max) {
              event.target.value = Math.max(Number(min), Math.min(Number(max), Number(value)))
              setInputValue(event.target.value)
            }  else {
              setInputValue(event.target.value)
            }
            break
          }
          default:
            setInputValue(event.target.value)
            break
        }

    }


    useEffect(() => {

      const delayInputTimeoutId = setTimeout(() => {
        const val = unformattedValue({type: props.type!, inputValue, maskOptions: props.maskOptions})
        setDebouncedInputValue(val ?? '');
      }, props.debounceTime ?? 500);
      return () => clearTimeout(delayInputTimeoutId);

    }, [inputValue]);

    useEffect(
    ()=>{
      props.onChange(debouncedInputValue);
    },
    [debouncedInputValue]);

    useEffect(
    ()=>{
      if(props.value) {
        switch(props.type) {
          case 'date':
            setInputValue(moment(props.value).format('yyyy-MM-DD'));
            break;            
          default:
            setInputValue(props.value);
            break;
        }
      }
    },
    [props.value]);

    useEffect(()=>{
      setInputValue(props.value);
    }, [props]);
    

    return (
        <>
        {
          generateInput(inputValue, handleInputChange, props)
        }
        {inputValue != '' && props.showClear ?
          <span className='clear-container' key={'clear-button'}>
            <SvgIcon component={CloseIcon} cursor={'pointer'} onClick={()=>setInputValue('')}></SvgIcon>
          </span>: null}
        </>
      );
}


export default InputFilter;