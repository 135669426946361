import { Drawer, List, Stack, Toolbar } from '@mui/material'
import appRoutes from '../../routes/appRoutes'
import SidebarItem from './SidebarItem'
import SidebarItemCollapse from './SidebarItemCollapse'
import UserService from '../../services/UserService'
import { useState } from 'react'


const Sidebar = () => {
  const [openDrawer, setOpenDrawer] = useState(true);

  return (
    <Drawer
      variant='permanent'
      className={openDrawer ? 'newsan-sidebar nwsn-scroll' : 'newsan-sidebar small nwsn-scroll'}
    >
      <List disablePadding>
        <Toolbar sx={{ marginBottom: '20px', minHeight: '50px!important' }}>
          <Stack direction='column' justifyContent='center' alignItems='center'>
            <div onClick={()=>{ 
              setOpenDrawer(!openDrawer);
            }}
            className={openDrawer ? 'newsan-logo' : 'newsan-icon'}
            >
              
            </div>
            {openDrawer ? <div style={{fontFamily: 'Assistant-SemiBold', position: 'relative', top: '-16px', right: '-38px', color: 'var(--primary-color)'}}>Autostock</div>: null}
          </Stack>
        </Toolbar>
        {appRoutes
          .filter((r) => (r.roles && UserService.hasRole(r.roles)) || !r.roles)
          .map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} drawerExpanded={openDrawer} />
              ) : (
                <SidebarItem item={route} key={index}  drawerExpanded={openDrawer}/>
              )
            ) : null,
          )}
      </List>
    </Drawer>
  )
}

export default Sidebar
