/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosInstance } from 'axios';
import { IErrorResponse } from '../components/hooks/useData';
import { toast } from 'react-toastify'
import AxiosInterceptor from './http/AxiosInterceptor';

export default class BaseService {
    baseURL: string | undefined = process.env.REACT_APP_API_BASE_URL;
    client: AxiosInstance;

    constructor() {
        this.client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL);
    }


     handleError(err: any): void {

            const error = err as Error | AxiosError<IErrorResponse>
            if (axios.isAxiosError(error)) {
            if (error.code === AxiosError.ERR_CANCELED) {
                return
            }
            console.error(error?.response?.data?.message || error.message);
            if(Array.isArray(error?.response?.data?.message)) {
                toast.error(error?.response?.data?.message[0], {
                    position: toast.POSITION.TOP_RIGHT,
                });

            } else {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            
            } else {
            console.error(error.message);
            toast.error(error.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
     }

}