/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
// import useData from '../../components/hooks/useData'
import Topbar from '../../components/common/topbar/Topbar'
import {  Notification, NotificationResponse, TableAction, TableDetailCell, TableHeaderCell } from '../../model/models-module'
import { addParam } from '../../utils/CommonUtils'
import {useData} from '../../components/hooks/useData'
import DataTable from '../../components/common/datatable/Datatable'
import { Box, Button, CircularProgress, IconButton, Menu, MenuItem, Modal, SvgIcon, TableCell, TableHead, TableRow, Tooltip, Typography} from '@mui/material'
import UserService from '../../services/UserService'
import NewsanIcons from '../../components/common/Icons'
import classes from './Notificactions.module.css'
import AxiosInterceptor from '../../services/http/AxiosInterceptor'
import axios from 'axios';
import {ReactComponent as CheckboxIcon} from '../../assets/icons/checkbox.svg';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'

const client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL)

// const gridActions = 
const NotificationsPage = () => {
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([]);
  const [headerActions, setHeaderActions] = useState<TableAction[]>([]);
  const [dataTo, setDataTo] = useState<number>(50);
  const [countItems, setCountItems] = useState<number>(0);
  const [dataOffset, setDataOffset] = useState<number>(0);
  const [username] = useState<string>(UserService.getUsername());

  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]);
  const [page, setPage] = useState<number>(1);
  const [selected, setSelected] = useState<number[]>([]);
  const [dataChecked, setDataChecked] = React.useState<Map<number, any>>(new Map());
  const [checkAll, setCheckAll] = React.useState<boolean>(false);

  const [baseEndpoint, setBaseEndpoint] = useState<string>('/notifications/');  
  const [endpoint, setEndPoint] = useState<string>('');  
  const [params] = useState<Map<string,string>>(new Map());  
  const [loading, error, dataGrid] = useData<NotificationResponse>(baseEndpoint != '' ? endpoint : `/notifications/?user=${username}&status=unread,read`, 'GET');
  const [loadingTotal, errorTotal, dataGridTotal] = useData<NotificationResponse>(`/notifications/?user=${username}&status=unread,read`, 'GET');

  const [loadingResponse, setLoadingResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState<string | null>(null);

  const [anchorElCheck, setAnchorElCheck] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorElCheck);
  const [openNoti, setOpenNoti] = useState(false);
  const [notificationToOpen, setNotificationToOpen] = useState<null | Notification>(null)

  useEffect(()=> {
    addParam(username, 'user', params, baseEndpoint, setEndPoint);
  }, [username])

  useEffect(()=> {
    addParam('unread,read', 'status', params, baseEndpoint, setEndPoint);
  }, [])

  useEffect(()=> {
      addParam(dataTo, 'limit', params, baseEndpoint, setEndPoint);
  }, [dataTo])

  useEffect(()=> {
      addParam(dataOffset, 'offset', params, baseEndpoint, setEndPoint);
  }, [dataOffset])

  const handleOpenCheckMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElCheck(event.currentTarget)
  }

  const handleCloseCheckMenu = () => {
    setAnchorElCheck(null)
  }
  
  const formatearFecha = (date: string) => {
    const fechaObj = new Date(date);
    
    const año = fechaObj.getFullYear();
    const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
    const dia = fechaObj.getDate().toString().padStart(2, '0');
    const hora = fechaObj.getHours().toString().padStart(2, '0');
    const minutos = fechaObj.getMinutes().toString().padStart(2, '0');
    
    return `${dia}/${mes}/${año} ${hora}:${minutos}`;
  }

  if(error || errorTotal || errorResponse) {
    console.error(error, errorTotal, errorResponse);
  }
  if(loadingResponse) console.warn(loadingResponse)
  

  const setNotificationReadOrUnRead = async (noti: Notification) => {
    try{
        await client.patch(`/notifications/${noti.id}`, noti.status == 'read' ? {'status': 'unread'} : {'status': 'read'})
        setErrorResponse(null)
        setBaseEndpoint('');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse(error.message);
      } else {
        setErrorResponse('Hubo un error en la solicitud');
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  const eliminateNotification = async (id: number) => {
    try{
        await client.patch(`/notifications/${id}`, {'status': 'deleted'})
        setErrorResponse(null)
        setBaseEndpoint('');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse(error.message);
      } else {
        setErrorResponse('Hubo un error en la solicitud');
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  const massiveStatusChange = async (ids: number[], status: string) => {
    try{
        await client.patch('/notifications', {'ids': ids, 'status': status})
        setErrorResponse(null)
        setBaseEndpoint('');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse(error.message);
      } else {
        setErrorResponse('Hubo un error en la solicitud');
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  const massiveSelect = (statusSelect : string)  => {
    const mapAux = new Map();
      dataShow.forEach((r, i)=>{
        if (r.data.status == statusSelect) mapAux.set(i, r);
      });
    setDataChecked(mapAux);
  }

  const clickNotification = (notification: Notification) => {
    setNotificationToOpen(notification)
    setOpenNoti(true)
    if (notification.status == 'unread') setNotificationReadOrUnRead(notification)
  }

  useEffect(()=> {
    const filterLoadeed = !loading && dataGrid != null && dataHeader.length === 0;

  if(filterLoadeed ) {
    setDataHeader([
      new TableHeaderCell({label: 'Criticidad', key: 'criticality', sorteable: false}),
      new TableHeaderCell({label: 'Fecha', key: 'created', sorteable: true, headerStyle:{minWidth: '140px'}}),
      new TableHeaderCell({label: 'Mensaje', key: 'message', sorteable: false, colStyle:{minWidth: '34vw'}}),
      ]);
  }
  const showUnRead = dataGrid?.results.some(obj => selected.includes(obj.id) && obj.status === 'unread');

  if (selected.length != 0) {
    setHeaderActions([
      
      showUnRead ?
      new TableAction({label: 'Marcar como leida', action: async () => {
        setLoadingResponse(true)
        massiveStatusChange(selected, 'read')
      },
      icon: <span className={classes['readIconHeader']}>{NewsanIcons.MAIL_AS_READ}</span>,
      }): 
      new TableAction({label: 'Marcar como no leida', action: async () => {
        setLoadingResponse(true)
        massiveStatusChange(selected, 'unread')
      },
      icon: <span className={classes['unreadIconHeader']}>{NewsanIcons.MAIL_AS_UNREAD}</span>,
      }),
      new TableAction({label: 'Eliminar', action: async () => {
        setLoadingResponse(true)
        massiveStatusChange(selected, 'deleted')
      },
      icon: <span className={classes['eliminateIconHeader']}>{NewsanIcons.DELETE}</span>,
      })
    ])
  } else {
    setHeaderActions([])
    setCheckAll(false)
  }
  }, [selected])

  useEffect(()=>{
    if (baseEndpoint == '') setBaseEndpoint('/notifications/');

    if(!loadingResponse && !loading && !loadingTotal && dataGrid && dataGridTotal) {
      setCountItems(dataGridTotal.count);
      setDataShow(dataGrid.results.map((r) => {
        let styleIcon = null;
            if (r.criticality == 'high'){
                styleIcon = 'iconRed';
            }else if (r.criticality == 'medium'){
                styleIcon = 'iconYellow'
            } else if (r.criticality == 'low'){
                styleIcon = 'iconBlue'
            }
        return new TableDetailCell({...r},[
         new TableAction({label: r.status == 'read' ? 'Marcar como no leida': 'Marcar como leida', action: async () => {
              setLoadingResponse(true)
              await setNotificationReadOrUnRead(r);
              setBaseEndpoint('');
          },
          icon: <span className={classes['editIcon']}>{r.status == 'read' ? NewsanIcons.MAIL_AS_UNREAD : NewsanIcons.MAIL_AS_READ}</span>,
          }),
          new TableAction({label: 'Eliminar', action: () => {
              setLoadingResponse(true)
              eliminateNotification(r.id);
          },
          icon: <span className={classes['eliminateIcon']}>{NewsanIcons.DELETE}</span>,
          }),
        ],
          new Map<string, React.ReactNode>([
            ['message', <div key={r.id} className={classes['messageContainer']}><Typography fontSize={'14px'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>{r.title}</Typography> <Typography fontSize={'13px'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>{r.description}</Typography></div>],
            ['criticality', <Tooltip title={r.type + ' - ' + r.criticality} key={r.id} className={classes[styleIcon!]}>{r.type == 'info' ? NewsanIcons.INFO_OUTLINED : NewsanIcons.WARNING}</Tooltip>],
            ['created', <span key={'created'} className={classes['createdfield']}>{formatearFecha(r.created!)}</span> ]
        ]),
        r.status == 'read' ? classes['readRow'] : classes['unreadRow'],
        clickNotification
        )
      }))
    }
  }, [setDataShow, dataGrid, loading, baseEndpoint, dataTo, dataOffset]);

  useEffect(
    ()=>{
        const mapAux = new Map();
        if(checkAll) {
            dataShow.forEach((r, i)=>{
                mapAux.set(i, r);
            });
        }
        setDataChecked(mapAux);
    }, 
    [ checkAll ]);

    useEffect(
      ()=>{
          const idsSelected : number[] = [];
          dataChecked.forEach((value)=> {
            idsSelected.push(value.data.id)
          })
          setSelected(idsSelected);
      }, 
    [ dataChecked ]);

  const onSort = (key: string, order: string)=>{
    const sortedData = [...dataShow]; 
    if (order === 'ASC' || order === 'DESC'){
      sortedData.sort((a: any, b: any) => {
        const valueA = getProperty(a, key);
        const valueB = getProperty(b, key);
        if (order === 'ASC') {
          return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
        } else {
          return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
        }
      });

      setDataShow(sortedData);
    }      
  };

  const onCheckAllFn = useCallback(
    (action: TableAction) => { 
      if(action.action) {
        action.action('header',0); 
      }
      if(checkAll) {
        setCheckAll(!checkAll);
      }
    }
  , [setCheckAll]);
  
  const getProperty = (obj: any, key: string): any => {
    const value = obj.data[key]
    return value;
  };

  function reloadTable(){
    setBaseEndpoint('')
  }

  return (
    <div className={classes.notificationPage}>
    {
      loading || loadingResponse ?
      <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
      : null
    }
      <Topbar title={'Notificaciones'} hasReloadIcon reloadPage={reloadTable}/> 

    <Modal
        open={openNoti}
        onClose={()=>{setOpenNoti(false)}}
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          height: 'fit-content',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 5 ,
          p: 4,
          border: '3px solid var(--secondary-color)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Typography variant="h6" component="h2" fontWeight={'bold'} color={'var(--primary-color)'}>
            {notificationToOpen?.title}
          </Typography>
          <Typography sx={{ mt: 2, overflow: 'auto', maxHeight: '450px' }}>
            {notificationToOpen?.description}
          </Typography>
          <Button className={classes['buttonModal']} onClick={()=>{setOpenNoti(false)}}>
            Cerrar
          </Button>
        </Box>
      </Modal>
      <div className={classes['container-abm-page']}>
        <div className={classes['customActionHeader']}>
          <TableHead sx={{ minWidth: '700px' }}>
            <TableRow key="header">
              <TableCell key='selectHeader' className='checkbox-datatable'>
                        <span className={classes['checkAllContainer']}>
                            <SvgIcon onClick={()=>setCheckAll(!checkAll)}
                            className={ checkAll ? ' checked' : ''} component={CheckboxIcon} viewBox="0 0 18 22" />
                            {open ? <IconButton className={classes['openCheck']} onClick={handleCloseCheckMenu} sx={{ p: 0 }}><ExpandLessOutlinedIcon /></IconButton> : <IconButton className={classes['openCheck']} onClick={handleOpenCheckMenu} sx={{ p: 0 }}><ExpandMoreOutlinedIcon /></IconButton>}
                            
                        </span>
                        <Menu
                          sx={{ mt: '45px' }}
                          anchorEl={anchorElCheck}
                          style={{height: '32vw'}}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          open={Boolean(anchorElCheck)}
                          onClose={handleCloseCheckMenu}
                        >
                          <div className='content'>
                          <MenuItem key={'all'} onClick={() => {handleCloseCheckMenu(); setCheckAll(true)}} style={{paddingInline: '10px', cursor: 'auto'}}>Todas</MenuItem>
                          <MenuItem key={'read'} onClick={() => {handleCloseCheckMenu(); massiveSelect('read')}} style={{paddingInline: '10px', cursor: 'auto'}}>Leídas</MenuItem>
                          <MenuItem key={'unread'} onClick={() => {handleCloseCheckMenu(); massiveSelect('unread')}} style={{paddingInline: '10px', cursor: 'auto'}}>No leídas</MenuItem>
                          <MenuItem key={'none'} onClick={() => {handleCloseCheckMenu(); setDataChecked(new Map()); setCheckAll(false)}} style={{paddingInline: '10px', cursor: 'auto'}}>Ninguna</MenuItem>
                          </div>
                        </Menu>
              </TableCell>
              {headerActions.map((action, i)=>{
                return (
                  <TableCell key={i} >
                    <IconButton title={action.label} onClick={()=>onCheckAllFn(action)}>{action.icon}</IconButton>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
        </div>
        <div className={classes.tableContainer}>
            <DataTable
            className={classes.tableStyle}
            headers={dataHeader}
            rows={dataShow}
            countItems={countItems}
            rowsPerPage={dataTo}
            hasActions={true}
            appendOnScrollToBottom={false}
            onPageSizeChange={(size) => {setDataTo(size); setDataOffset(0); setPage(1);}}
            onPageChange={(page: number)=>{ setPage(page); setDataOffset(page-1) }}
            onSortChange={onSort}
            onSelectChange={(selectedOptions)=> {setSelected(selectedOptions)}}
            pageSelected={page}
            pageable={true}
            selectable={true}
            groupActions={false}
            showHeader={false}
            dataCheckedList={dataChecked}
            >
            </DataTable>
        </div>
      </div>
    </div>
  )
}

export default NotificationsPage;
