import NewsanIcons from '../../../components/common/Icons';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { FileResult } from '../../../model/models-module';
import classes from './FileStatusCard.module.css'
import { useData } from '../../../components/hooks/useData';
import { useEffect, useState } from 'react';


type Prop = {
    fileResult: FileResult;
    onDelete(file: FileResult): void;
    onClick(file: FileResult): void;
    selected?: boolean;
};

const FileStatusCard = ({fileResult, onDelete, onClick, selected = false} : Prop)=> {
  const [refreshEndpoint, setRefreshEndpoint] = useState<string>('');
  const [loading, error, data] = useData<FileResult>(refreshEndpoint, 'GET');
  const [refresh, setRefresh] = useState<boolean>(false);
  const [result, setResult] = useState<FileResult>(new FileResult(fileResult));

  useEffect(()=>{
    if(refresh) {
      setRefreshEndpoint(`files/status/${result.id}`);
      setRefresh(false);
    }

  }, [refresh]);

  useEffect(()=>{
    if(!loading && data) {
      setResult(new FileResult(data));
      setRefreshEndpoint('');
    }

  }, [loading, data]);

  if(error) {
    console.error(error);
  }
  

return <span className={selected ? `${classes['file-status-card']} ${classes.selected}` : classes['file-status-card']} onClick={()=>onClick(result)}>
            {
              loading ? <>Cargando..</>
              :
            <>
                      <div className={classes['file-status-card-icon']}>
                        {NewsanIcons.XLSX_FILE}
                      </div>
                      <div className={classes['file-status-card-filename-container']}>
                        <span className={classes['file-status-card-filename']}>
                          {result.filename}
                        </span>
                        <span className={`${classes['file-status-card-result']} ${result.statusCode}`}>
                          {result.message}
                        </span>
                      </div>
                      <div className={classes['file-status-card-datetime-container']}>
                        <span className={classes['file-status-card-datetime']}>
                          {result.created}
                        </span>
                      </div>
                      <div className={classes['file-status-card-icons-container']}>
                        
                          { 
                          result.status ?
                          result.statusCode === 'success' ?
                          <span className={classes['success-result']}>{NewsanIcons.CHECK}</span> : 
                          <span className={classes['error-result']}>{NewsanIcons.CANCEL_ERROR}</span>
                          :
                          null
                          }
                          <span onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); onDelete(result)}}>{NewsanIcons.DELETE}</span>
                          {
                              result.statusCode === 'pending' ?
                              <span onClick={(e)=>{ e.preventDefault(); e.stopPropagation(); setRefresh(true);}}><RefreshOutlinedIcon style={{ bottom: '14px' }}></RefreshOutlinedIcon></span>
                              : null
                          }
                      </div>
                      {/* {
                          result.statusCode === 'error' ?
                          <span className={classes['float-error-icon']}>
                              {NewsanIcons.WARNING}
                          </span>
                          : null
                      } */}
            </>
            }
          </span>;}

export default FileStatusCard;