/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import Topbar from '../../components/common/topbar/Topbar'
import { CircularProgress, Tooltip } from '@mui/material'
import DataTable from '../../components/common/datatable/Datatable'
import classes from './AssignStock.module.css';
import { Stock, TableAction, TableDetailCell, TableHeaderCell } from '../../model/models-module'
import { useNavigate, useParams } from 'react-router-dom'
import RefreshIcon from '@mui/icons-material/Refresh';
import { useData } from '../../components/hooks/useData'
import { GridDTO } from '../../model/dto-module'
import StockService from '../../services/StockService'
import NewsanIcons from '../../components/common/Icons'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import NewReservation from './new-reservation/NewReservation';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';


const dataHeader: TableHeaderCell[] = [
  {label: 'CD', key: 'distributionCentreCode', sorteable: false, minWidth: 100, colStyle:{padding: '10px 30px;'}, headerStyle:{marginLeft: '-7px'}},
  {label: 'Tipo de reserva', key: 'reserveTypeCode', sorteable: false, minWidth: 140, colStyle:{padding: '10px 16px;'}, headerStyle:{marginLeft: '-7px'}},
  {label: 'Warehouse', key: 'warehouseCode', sorteable: false, colStyle:{padding: '10px 16px;'}, headerStyle:{marginLeft: '-7px'}},
  // {label: 'Stock reservado', key: 'stockReserved', sorteable: false, minWidth: 80, align: 'center', colStyle:{  textAlign: 'center' }, headerStyle: { textAlign: 'center' }},
  {label: 'Nueva reserva', key: 'stockReserved', sorteable: false, minWidth: 160, align: 'center', colStyle:{padding: '10px 16px;', textAlign: 'center'}, headerStyle:{marginLeft: '-7px'}},
];

const AssignStock = () => {

  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]); 
  const [mapResult, setMapResult] = React.useState<Map<string, number> | null>(null);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [mapOrigVal] = useState<Map<number, any>>(new Map());

  const {sku, desc} = useParams();
  const navigate = useNavigate();
  const [endpoint, setEndPoint] = useState<string>(`reserved-stocks?productCode=${sku}`);
  const [loading, error, dataGrid] = useData<GridDTO<Stock>>(endpoint, 'GET');
  const [stockCentre, setStockCentre] = useState<ReactNode[]>([]);
  const [dataEdit, setDataEdit] = useState<Stock|undefined>();

  const editRow = useCallback((d: Stock)=> {
    setDataEdit(d);
  }, [ setDataEdit ]);

  useEffect(()=>{
    if(dataEdit!) {
      setShowModal(true);
    }
  }, [dataEdit]);

  const [service] = useState(StockService.Instance);
  const [initMap, setInitMap] = useState<boolean>(true);

  const preventsEventsOnLoading = useCallback((e: any)=>{
    if(showSpinner) {
      e.stopPropagation();
      e.preventDefault();
    }
    return false;
  }, [showSpinner]);

  const refreshsStock = useCallback(()=>{
    setMapResult(null); 
    setInitMap(true);
  }, [])

  const createRow = (d: Stock, i:number)=> {
    return new TableDetailCell(d,
      (d.id != undefined && d.id === 0 ) ? [] :
      [
      new TableAction({label: 'Eliminar',
      enable:d.pendingDate === null,
      action: ()=>{
            setShowSpinner(true);
        service.deleteStock(d.id!).then((resp)=>{
              if(resp) {
                setDataShow([]);
                setEndPoint('');
                refreshsStock();
              }
            }
            ).finally(()=>setShowSpinner(false));
      }, 
            cssClass: classes.customAction,
            icon: NewsanIcons.DELETE}),
      new TableAction({label: 'Editar', 
      enable:d.pendingDate === null,
      action: (r)=>{
            if(r) {
              console.warn('Edit Row');
              editRow(r);
            }
      },
            cssClass: classes.customAction,
            icon: <span className={classes.editIcon}><EditIcon></EditIcon></span>})
    ],
      new Map<string, React.ReactNode>([
        ['stockReserved',

        <div key={`container-${i}`} className={classes.customElement}>
          <span className={d.pendingDate! ? classes.reserveCellData : ''}>{d.stockReserved}</span> 
          {
            d.pendingDate! ?
            <Tooltip title={`Posee una ${d.pendingMoveType} por la cantidad de ${d.pendingQty} \n\rrealizado la fecha ${moment(d.pendingDate).format('DD/MM/YYYY')}`}>
              <WarningAmberIcon className={classes.warningIcon}></WarningAmberIcon>
            </Tooltip>
            : null
          }
        </div>
        
        ]
      ]
      ), (d.id != undefined && d.id === 0) ? classes.redRow : '');

  }

  useEffect(()=>{
    if(sku && initMap) {
      setInitMap(false);
      setShowSpinner(true);
      service.allStocksByCenters(sku).then((a)=>{
        if(a) {
          setMapResult(a);
        }
      }).finally(()=>setShowSpinner(false));
    }
  }, [sku, initMap]);

  useEffect(()=>{
    const aux: ReactNode[] = [];
    if(mapResult) {
      mapResult.forEach((v,k)=>{
        aux.push(
          <div key={k} style={{ width: '100%', height: '100%' }}>
            <div style={{ width: '50%', display: 'inline-flex', height: '100%' }}>
              <span className={classes['card-desc']}>{k}</span>
            </div>
            <div style={{ width: '50%', display: 'inline-flex', height: '100%' }}>
              <span className={classes['card-desc']}>{v}</span>
            </div>
          </div>)
      });
    }
    setStockCentre(aux);
  }, [mapResult]);  


  useEffect(()=>{
    if(endpoint === '') {
      setEndPoint(`reserved-stocks?productCode=${sku}`);
    }
    if(!loading && dataGrid!) {
      let aux: any[] = []; 
      aux = aux.concat(dataGrid.results.map((d, idx)=>{ 
        mapOrigVal.set(d.id!, d.stockReserved);
        return createRow(d, idx);
      }));
      setDataShow(aux);
    }
  }, [setDataShow, dataGrid, loading, endpoint]);

  function reloadTable() {
    setEndPoint('')
  }


  return <div className={classes.assignStockPage} onClick={preventsEventsOnLoading} onKeyDownCapture={preventsEventsOnLoading} onBlur={preventsEventsOnLoading} onKeyDown={preventsEventsOnLoading}>

    {
    showSpinner || loading ?
      <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
    : null
    }
    {
      showModal ?
      <NewReservation 
      open={showModal}
      sku={sku}
      data={dataEdit}
      fixedSku={true}
      editMode={dataEdit !== undefined}
      onClose={(e, reason)=>{
      console.warn(e);
      if (reason && reason === 'backdropClick') 
          return;

      setShowModal(false);
      setDataEdit(undefined);
      if (reason && reason === 'confirm')  {
        setEndPoint('');
        refreshsStock();
      }
    }}
      />
      :
      null
    }
    <Topbar title={'Asignación de stock'} onGoBack={()=>navigate('/stock')} hasReloadIcon reloadPage={reloadTable}>
    </Topbar>
    <div style={{
          marginTop: '10px',
          marginBottom: '22px',
          height: '41vh',
          minHeight: '320px',
        }}>

        <div className={classes['assign-stock-header-container']}>
          <span className={classes['static-card-assign-stock']}>
            <span className={classes['card-title']}>SKU</span>
            <span className={classes['card-desc']}>{sku}</span>
          </span>
          <span className={classes['static-card-assign-stock'] + ' ' + classes['desc-card-assign-stock']}>
            <span className={classes['card-title']}>DESCRIPCIÓN</span>
            <span className={classes['card-result']}>{desc}</span>

          </span>

          <span className={classes['static-card-assign-stock'] + ' nwsn-scroll ' + classes['info-card-assign-stock']}>
            <i className={classes['card-refres-icon']} onClick={()=>{refreshsStock();}}>
              <RefreshIcon  fontSize={'small'}></RefreshIcon>
            </i>
            <div style={{width: '100%', height: '100%'}}>
              <div style={{width: '50%', display: 'inline-flex', height: '100%'}}>
                <span className={classes['card-title']}>CENTRO DIST.</span>
              </div>
              <div style={{width: '50%', display: 'inline-flex', height: '100%'}}>
                <span className={classes['card-title']}>DISPONIBLE</span>
              </div>
            </div>
            {
              stockCentre.map(a=>a)
            }
            

          </span>
        </div>
        <div className={classes['assign-stock-grids-container']}>
          <div style={{textAlign: 'center', position:'relative'}}>

            <div className={classes.buttonAddReserve}>
              <span onClick={()=>{
                setShowModal(true);
                // setAddRow(true);
              }}>
                {NewsanIcons.PLUS}
              </span>
              <span className={classes.text} onClick={()=>{
                setShowModal(true);
                // setAddRow(true);
              }}>
                Agregar Reserva
              </span>
            </div>
            <h3 className={classes['assign-table-title']}>RESERVA</h3>
          </div>
          <div className={classes.gridContainer}>
            {
              error ? error :
            <DataTable headers={dataHeader}  rows={dataShow} className={classes['assign-stock-table']}
                countItems={dataGrid ? dataGrid.results.length : 0}  rowsPerPage={100} hasActions={true} groupActions={false}
                appendOnScrollToBottom={true} onScrollToBottom={(pageSize: number)=>console.warn(pageSize)} ></DataTable>
            }
          </div>
          {/* <div className='side-grid new-stock-side'>
            <DataTable headers={dataHeader2}  rows={dataShow2} className={'assign-stock-table'}
                countItems={dataGrid.length}  rowsPerPage={3}
                appendOnScrollToBottom={true} onScrollToBottom={(pageSize: number)=>setDataTo2(pageSize)} ></DataTable>
            <span className='assing-stock-character-container'>
              {NewsanPics.CHARACTER}
            </span>
          </div> */}
        </div>
    </div>
    </div>
}

export default AssignStock
