import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import classes from './PopupSuccessError.module.css'
import { Typography } from '@mui/material';

interface PopupSuccessErrorProps {
    open: boolean;
    onClose: () => void;
    loading: boolean;
    success: boolean;
    successMessage: string | null;
    errorMessage: string[] | null;
  }

const PopupSuccessError = ({ open, onClose, loading, success, successMessage, errorMessage }: PopupSuccessErrorProps) => {
  console.warn(errorMessage)
    return !loading ? (<Dialog open={open} onClose={onClose}>
        <DialogContent>
        {success ? 
          <Typography id="modal-modal-title" >
             {successMessage}
          </Typography>:
          (
            <>
              {errorMessage!.length > 1 ? <Typography>Los siguientes campos son invalidos: </Typography>: <Typography>El siguiente campo es invalido: </Typography>}
              <ul>
                {errorMessage?.map((message, i) => (
                  <li key={i}>
                    <Typography >{message}</Typography>
                  </li>
                ))}
              </ul>
            </>
          )
        }
        </DialogContent>
        <DialogActions style={{justifyContent: 'center', marginBottom: '8px'}}>
          <Button onClick={onClose} className={classes['buttonCancel']}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog >):
      <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
  };
  
  export default PopupSuccessError;
