/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService from './BaseService'
import { CreateWarehouseReserveTypeDistributionDTO } from '../pages/stock-distribution/interface'
import { GridDTO } from '../model/dto-module';
import { ReservedType } from '../model/models-module';

export default class WarehouseReserveTypeDistritubionService extends BaseService {
  private static _instance: WarehouseReserveTypeDistritubionService

  public static get Instance() {
    return this._instance || (this._instance = new this())
  }

  async findByQuery({limit = 20, offset=0}: any = {}) :Promise<GridDTO<ReservedType>> {
    try {
      const url = `/warehouse-reserve-types-distribution?limit=${limit}&offset=${offset}`;

      const response = await this.client({
        url,
        method: 'GET',
      })

      return response.data.data
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }

  async create(data: CreateWarehouseReserveTypeDistributionDTO) {
    try {
      const response = await this.client({
        url: 'warehouse-reserve-types-distribution',
        method: 'POST',
        data: data,
      })

      return response.data
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }

  async update({ id, data }: { id?: number; data: CreateWarehouseReserveTypeDistributionDTO }) {
    try {
      const response = await this.client({
        url: `warehouse-reserve-types-distribution/${id}`,
        method: 'PATCH',
        data: data,
      })

      return response.data
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }

  async delete(id: number) {
    try {
      const response = await this.client({
        url: `warehouse-reserve-types-distribution/${id}`,
        method: 'DELETE',
      })

      return response.data
    } catch (err: any) {
      this.handleError(err)
      throw err
    }
  }
}
