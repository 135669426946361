/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReservedType, Stock } from './models-module';

class StockDTO {
    distributionCentreCode?: string;
    productCode?: string;
    productDescription?: string;
    reserveTypeCode?: string;
    stockReserved?: number;
    newStockReserved?: number;
    warehouseCode?: string;
    stockAvailable?: number
    
    constructor(data?:StockDTO|Stock) {
        if(data) {
            this.distributionCentreCode = data.distributionCentreCode;
            this.productCode = data.productCode;
            this.productDescription = data.productDescription;
            this.reserveTypeCode = data.reserveTypeCode;
            this.stockReserved = data.stockReserved;
            this.warehouseCode = data.warehouseCode;
            this.newStockReserved = 0;
        }

    }
}

class ReserveDTO {
    type?: string;
    inventoryItemId?: number;
    sku?: string;
    description?: string;
    organizationCode?: string; 
    reservationQuantity?: number; 
    reserveType?: string;

    constructor({type, inventoryItemId, sku, description, organizationCode, reservationQuantity, reserveType}: ReserveDTO) {
        this.type = type;
        this.inventoryItemId = inventoryItemId;
        this.sku = sku;
        this.description = description;
        this.organizationCode = organizationCode;
        this.reservationQuantity = reservationQuantity;
        this.reserveType  = reserveType;
    }
}

class SalesRateResultDTO {
  warehouseCode?: string;
  warehouseDescription?: string;
  productDescription?: string;
  productCode?: string;
  intervalDays?: number;
  totalSales?: number;
  hourlySalesRate?: number;
  stockReserved?: number;
  stockMinimum?: number;
  hoursForMinimum?: number;

    constructor({warehouseCode, warehouseDescription, productDescription, productCode, intervalDays, totalSales, hourlySalesRate, stockReserved, stockMinimum, hoursForMinimum}: SalesRateResultDTO) {
        this.warehouseCode = warehouseCode ?? this.warehouseCode;
        this.warehouseDescription = warehouseDescription ?? this.warehouseDescription;
        this.productDescription = productDescription ?? this.productDescription;
        this.productCode = productCode ?? this.productCode;
        this.intervalDays = intervalDays ?? this.intervalDays;
        this.totalSales = totalSales ?? this.totalSales;
        this.hourlySalesRate = hourlySalesRate ?? this.hourlySalesRate;
        this.stockReserved = stockReserved ?? this.stockReserved;
        this.stockMinimum = stockMinimum ?? this.stockMinimum;
        this.hoursForMinimum = hoursForMinimum ?? this.hoursForMinimum;
    }
}


class GridDTO<T> {
    results: T[];
    count: number;

    constructor({results, count}: GridDTO<T>) {
        this.results = results ?? [];
        this.count = count ?? 0;
    }

}

class WrappedObj<T> {
    attributes?: T;
    id?: number;

    constructor({attributes, id}: WrappedObj<T>) {
        this.attributes = attributes;
        this.id = id;
    }

}

class NewReserveDTO {
    distributionCentreCode?:string;
    productCode?:string;
    productDesc?:string;
    reserveTypeCode?:string;
    reserveType?:ReservedType;
    warehouseId?:number;
    stockReserved?: number;

    constructor( {distributionCentreCode, productCode: sku, productDesc, reserveType, reserveTypeCode, warehouseId, stockReserved}: NewReserveDTO = {}) {
        this.distributionCentreCode = distributionCentreCode;
        this.productCode = sku;
        this.productDesc = productDesc;
        this.reserveTypeCode = reserveTypeCode;
        this.reserveType = reserveType;
        this.warehouseId = warehouseId;
        this.stockReserved = stockReserved;
    }
}


 class CreateReservedStockDto {
  distributionCentreCode?: string;
  productCode?: string;
//   productDescription?: string;
  reserveTypeCode?: string;
  stockReserved?: number;
  constructor({distributionCentreCode, productCode, reserveTypeCode, stockReserved}: CreateReservedStockDto = {}) {
    this.distributionCentreCode = this.distributionCentreCode ?? distributionCentreCode;
    this.productCode = this.productCode ?? productCode;
    // this.productDescription = this.productDescription ?? productDescription;
    this.reserveTypeCode = this.reserveTypeCode ?? reserveTypeCode;
    this.stockReserved = Number(this.stockReserved ?? stockReserved).valueOf();
    console.warn(this);
  }
}

class User {
    username?: string;
    firstName?: string;
    lastName?: string;
    email?: string;

    constructor({username = '', firstName = '', lastName = '', email = ''}: User = {}) {
        this.username = username;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
    }
}

class Role {
    name?: string;

    constructor({name = ''}: Role = {}) {
        this.name = name;
    }
}


export {
    StockDTO,
    ReserveDTO,
    SalesRateResultDTO,
    GridDTO,
    WrappedObj,
    NewReserveDTO,
    CreateReservedStockDto,
    User,
    Role,
};