/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, CircularProgress, FormControl, MenuItem, Select } from '@mui/material';
import Topbar from '../../components/common/topbar/Topbar';
import DataTable from '../../components/common/datatable/Datatable';
import { useEffect, useState } from 'react';
import NewsanIcons from '../../components/common/Icons';
import classes from './Warehouse.module.css'
import { Warehouse, TableAction, TableDetailCell, WarehouseType, ReservesType, TableHeaderCell, FilterTable, FilterTableType  } from '../../model/models-module';
import CloseIcon from '@mui/icons-material/Close';
import AxiosInterceptor from '../../services/http/AxiosInterceptor';
import { useData } from '../../components/hooks/useData';
import axios from 'axios';
import PopupSuccessError from '../../components/common/popup-success-error/PopupSuccessError';
import EditIcon from '@mui/icons-material/Edit';
import { GridDTO } from '../../model/dto-module';
import { addParam } from '../../utils/CommonUtils'


type typeBody = {
    description?: string;
    code?: string;
    status?: boolean;
    platform?: string;
    platformReserveType?: string;
    saleable?: boolean;
    transferProduct?: boolean;
    requestProduct?: boolean;
    warehouseTypeCode?: string;
    reserveTypeCode?: string;
}

const client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL)

const WarehousePage = () =>{
  const [dataShow, setDataShow] = useState<any>([])
  const [dataTo, setDataTo] = useState<number>(20)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [newEditItem, setNewEditItem] = useState<Warehouse | undefined>()
  const [countItems, setCountItems] = useState<number>(0);
  const [dataOffset, setDataOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [baseEndpoint, setBaseEndpoint] = useState<string>('/warehouses'); 
  const [warehouseSettingsEndpoint, setWarehouseSettingsEndpoint] = useState<string>('');
  const [loadingWarehouse, errorWarehouse, dataWarehouse]= useData<GridDTO<Warehouse>>(baseEndpoint != '' ? warehouseSettingsEndpoint : '/warehouses', 'GET')
  const [loadingWarehouseTypes, errorWarehouseTypes, dataWarehouseTypes] = useData<WarehouseType[]>('/warehouse-types', 'GET')
  const [loadingReserveTypes, errorReserveTypes, dataReserveTypes] = useData<GridDTO<ReservesType>>('/reserved-types', 'GET')
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([]);
  const [nameFilter, setNameFilter] = useState<string>()
  const [statusFilter, setStatusFilter] = useState<string>();

  const [selectedDescription, setSelectedDescription] = useState<string>('');
  const [selectedCode, setSelectedCode] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<boolean>();
  const [selectedPlatform, setSelectedPlatform] = useState<string>('');
  const [selectedPlatformReserveType, setSelectedPlatformReserveType] = useState<string>('');
  const [selectedSaleable, setSelectedSaleable] = useState<boolean>();
  const [selectedTransfer, setSelectedTransfer] = useState<boolean>();
  const [selectedRequest, setSelectedRequest] = useState<boolean>();
  const [selectedWarehouseType , setSelectedWarehouseType] = useState<WarehouseType>();
  const [selectedReserveType , setSelectedReserveType] = useState<ReservesType>();
  
  const [sortArg, setSortArg] = useState<string>('');
  const [newSort, setNewSort] = useState<number>(0);
  const [sort] = useState<Map<string, string>>(new Map());
  const [params] = useState<Map<string,string>>(new Map());
  const [loadingResponse, setLoadingResponse] = useState(false);
  const [errorResponse, setErrorResponse] = useState<string[] | null>(null);
  const [dataResponse, setDataResponse] = useState<string | null>(null);

  const [showPopup, setShowPopup] = useState<boolean>(false)

  const fetchData = async ( body?: typeBody) => {
    try{
      setLoadingResponse(true)
      if (isEdit){
        await client.patch(`/warehouses/${newEditItem?.id}`, body).then(()=> {
          setDataResponse('Modificado con éxito!')
          setErrorResponse(null)
          setIsEdit(false)
          setNewEditItem(undefined)
        }).catch((error) => {
          if (error.response.status === 400) {
            setErrorResponse(error.response.data.message[0])
          }
        })
      }else{
        await client.post('/warehouses', body)
        setDataResponse('Creado con éxito')
        setErrorResponse(null)
      }
      formatForm()
      setShowForm(false)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse([error.message]);
      } else {
        setErrorResponse(['Hubo un error en la solicitud']);
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  const formatForm = () => {
    setSelectedSaleable(false)
    setSelectedStatus(false)
    setSelectedCode('')
    setSelectedDescription('')
    setSelectedPlatform('')
    setSelectedPlatformReserveType('')
    setSelectedRequest(false)
    setSelectedTransfer(false)
    setSelectedWarehouseType(undefined)
    setSelectedReserveType(undefined)
  }

  const saveFormInfo = () => {
      const temporalObject: typeBody = {
        description: selectedDescription,
        code: selectedCode,
        status: selectedStatus,
        platform: selectedPlatform,
        platformReserveType: selectedPlatformReserveType,
        saleable: selectedSaleable,
        transferProduct: selectedTransfer,
        requestProduct: selectedRequest,
        warehouseTypeCode: selectedWarehouseType?.code,
        reserveTypeCode: selectedReserveType?.code,
      }
      setShowPopup(true);
      fetchData(temporalObject)
  }

  const deleteWarehouse = async (id: number) => {
    try{
      setLoadingResponse(true)
        await client.delete(`/warehouses/${id}`)
        setDataResponse('Eliminado con éxito!')
        setErrorResponse(null)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrorResponse(error.response?.data.message);
      } else if (error instanceof Error) {
        setErrorResponse([error.message]);
      } else {
        setErrorResponse(['Hubo un error en la solicitud']);
      }
    } finally {
      setLoadingResponse(false);
    } 
  }

  useEffect(()=> {
    addParam(dataTo, 'limit', params, baseEndpoint, setWarehouseSettingsEndpoint);
  }, [dataTo, baseEndpoint])

  useEffect(()=> {
      addParam(dataOffset, 'offset', params, baseEndpoint, setWarehouseSettingsEndpoint);
  }, [dataOffset, baseEndpoint])

  useEffect(()=> {
      setDataOffset(0)
      setDataShow([]);
      addParam(sortArg, 'sort', params, baseEndpoint, setWarehouseSettingsEndpoint);
    
  }, [sortArg, baseEndpoint])

  useEffect(()=> {
    setDataOffset(0)
    addParam(nameFilter, 'description', params, baseEndpoint, setWarehouseSettingsEndpoint);
  
}, [nameFilter, baseEndpoint])

useEffect(()=> {
  setDataOffset(0)
  addParam(statusFilter, 'status', params, baseEndpoint, setWarehouseSettingsEndpoint);

}, [statusFilter, baseEndpoint])

  useEffect(() => {
    if (baseEndpoint == '') setBaseEndpoint('/warehouses');

    if (!errorWarehouseTypes && dataWarehouseTypes){
      dataWarehouseTypes.sort((a, b) => {
        const descriptionA = a.description.toLowerCase();
        const descriptionB = b.description.toLowerCase();

        if (descriptionA < descriptionB) {
          return -1;
        }
        if (descriptionA > descriptionB) {
          return 1;
        }
        return 0;
      });
    }

    if (!errorReserveTypes && dataReserveTypes){
      dataReserveTypes.results.sort((a, b) => {
        const descriptionA = a.description!.toLowerCase();
        const descriptionB = b.description!.toLowerCase();

        if (descriptionA < descriptionB) {
          return -1;
        }
        if (descriptionA > descriptionB) {
          return 1;
        }
        return 0;
      });
    }


    if (!errorWarehouse && dataWarehouse) {
      setCountItems(dataWarehouse.count);
      setDataShow(dataWarehouse.results.map((r) => {
          return new TableDetailCell({description: r.description, platform: r.platform, status: r.status ? 'Activa' : 'Inactiva',reserveType: r.reserveType ? r.reserveType.description : 'null'}, [
            new TableAction({label: 'Editar', action: () => {
              formatForm()
              setNewEditItem(r)
              setIsEdit(true)
              setShowForm(true)
            },
            icon: <span className={classes.editIcon}><EditIcon></EditIcon></span>
            }),
            new TableAction({label: 'Eliminar', action: async () => {
              setShowPopup(true);
              await deleteWarehouse(r.id)
            },
            icon: NewsanIcons.DELETE
            }),
          ],
          undefined,
          classes['customRow']
        )
      })

      )
    }
  }, [setDataShow, setCountItems, baseEndpoint, loadingReserveTypes, loadingWarehouseTypes, loadingWarehouse, dataWarehouse, dataTo, dataOffset, warehouseSettingsEndpoint])

  useEffect(() => {
    let filterLoadeed = !loadingWarehouse && dataWarehouse != null &&  dataHeader.length === 0;
    filterLoadeed = filterLoadeed && !loadingWarehouseTypes && !loadingReserveTypes && dataWarehouseTypes != null && dataReserveTypes != null;

    if(filterLoadeed) {
      setDataHeader([
        new TableHeaderCell({label: 'Descripción', key: 'description', sorteable: true, colStyle: {paddingLeft: '33px'}, filter: new FilterTable({onChange: (newVal)=>{setNameFilter(newVal)}, placeholder: 'Busca aqui...' })}), 
        new TableHeaderCell({label: 'Plataforma', key: 'platform', sorteable: true, colStyle: {paddingLeft: '18px'}}),
        new TableHeaderCell({label: 'Estado', key: 'status', sorteable: true, colStyle: {paddingLeft: '33px'}, filter: new FilterTable({onChange: (newVal)=>{setStatusFilter(newVal)}, type: FilterTableType.COMBO, data: [{id: 0, code: 'false', description: 'Inactiva'}, {id: 1, code: 'true', description: 'Activa'}], useCodeField: true })}),
        new TableHeaderCell({label: 'Tipo reserva', key: 'reserveType', sorteable: true, colStyle: {paddingLeft: '18px'}}),
      ])
    }
  })

  useEffect(()=>{

    if(newEditItem && isEdit){
      // Setear campos del formulario con los valores del item a editar
      setSelectedDescription(newEditItem.description);
      setSelectedCode(newEditItem.code);
      setSelectedStatus(newEditItem.status);
      setSelectedPlatform(newEditItem.platform);
      setSelectedPlatformReserveType(newEditItem.platformReserveType);
      setSelectedSaleable(newEditItem.saleable);
      setSelectedTransfer(newEditItem.transferProduct);
      setSelectedRequest(newEditItem.requestProduct);
      setSelectedWarehouseType(newEditItem.warehouseType)
      setSelectedReserveType(newEditItem.reserveType)
    }

  }, [loadingWarehouse, newEditItem, isEdit]);

  useEffect(()=>{
    if(newSort > 0) {
      let args = '';
      sort.forEach((value)=>{
        if(args === '') {
          args += value;
        } else {
          args += `,${value}`;
        }
      });
      setSortArg(args);
      setNewSort(0);
    }
  }, [newSort, setSortArg, setNewSort]);

  const onSort = (key: string, order: string)=>{ 
    if(order === ''){ 
      sort.delete(key)
    } else {
      sort.set(key, `${key}:${order}`);
    }
    setNewSort(1);
  };

  function reloadTable(){
    setBaseEndpoint('')
    setWarehouseSettingsEndpoint('')
  }

    return (
      <>
      {loadingWarehouse || loadingResponse ?
        <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
        : null}
      
    <div className={classes.warehousePage}>
    <Topbar title={'Almacenes'} hasReloadIcon reloadPage={reloadTable}/>
    <div className={classes['container-abm-page']}>
    {
          errorWarehouse ?
          <>Ha habido un problema!</>
          :
        <div
        className={
          showForm ? classes['table-templates-container-form-visible'] : classes['table-templates-container']
        }
        >
          <DataTable
            headers={dataHeader}
            rows={dataShow}
            countItems={countItems}
            rowsPerPage={dataTo}
            hasActions={true}
            appendOnScrollToBottom={false}
            onPageSizeChange={(size) => {setDataTo(size); setDataOffset(0); setPage(1);}}
            onPageChange={(page: number)=>{ setPage(page); setDataOffset(page-1) }}
            pageSelected={page}
            pageable={true}
            selectable={false}
            onSortChange={onSort}
            className={classes.warehouseDataTable}
          ></DataTable>
        </div>
          }
    {
      showForm ?
        <div className={classes['abm-container']}>
          
            <div className={isEdit ? classes['edit-form-container'] : classes['add-form-container']}>
                <span className={classes['title-form']}>{NewsanIcons.ADD_STORE} <span className={classes['title-form-text']}>{isEdit ? 'Editar' :'Agregar'}</span></span>
                <CloseIcon className={classes['form-close-button']} onClick={()=>{setShowForm(false); setIsEdit(false); setNewEditItem(undefined); formatForm()}}></CloseIcon>
                <span className={classes['setting-attr-title']}>Nombre del almacén*</span>
                <input type="text" className={classes['setting-attr-input']} placeholder='Nombre del almacén' value={selectedDescription} onChange={(e) => {
                 setSelectedDescription(e.target.value);
                }}/>

                <span className={classes['setting-attr-title']}>Código del almacén*</span>
                <input type="text" className={classes['setting-attr-input']} placeholder='Código del almacén' value={selectedCode} onChange={(e) => {
                  setSelectedCode(e.target.value);
                }} />             

                <span className={classes['setting-attr-title']}>Plataforma*</span>
                <input type="text" className={classes['setting-attr-input']} placeholder='Plataforma' value={selectedPlatform} onChange={(e) => {
                setSelectedPlatform(e.target.value);
                }} />

                <span className={classes['setting-attr-title']}>Estado*</span>
                <FormControl className={classes['select-filter']} size='small'>
                  <Select
                    value={selectedStatus ? 'Activa' : selectedStatus == false ? 'Inactiva' : ''}
                    onChange={(e) => {
                      if (e.target.value == 'Activa') {
                        setSelectedStatus(true)
                      } else if (e.target.value == 'Inactiva') {
                        setSelectedStatus(false)
                      }
                    }}
                  >
                    <MenuItem value={''}>
                      <em className={classes['no-selection']}>Seleccionar</em>
                    </MenuItem>
                    <MenuItem className={classes['select-option']} key={'Activa'} value={'Activa'}>
                      Activa
                    </MenuItem>
                    <MenuItem className={classes['select-option']} key={'Inactiva'} value={'Inactiva'}>
                      Inactiva
                    </MenuItem>
                  </Select>
                </FormControl>

                <span className={classes['setting-attr-title']}>Tipo de reserva de plataforma*</span>
                <input type="text" className={classes['setting-attr-input']} placeholder='Tipo de reserva de plataforma' value={selectedPlatformReserveType} onChange={(e) => {
                setSelectedPlatformReserveType(e.target.value);
                }} />

                <span className={classes['setting-attr-title']}>Habilitada para la Venta*</span>

                <FormControl className={classes['select-filter']} size="small">
                  <Select value={selectedSaleable ? 'Si' : selectedSaleable == false ? 'No' : ''}  
                    onChange={(e) => {
                    if (e.target.value == 'Si') {
                      setSelectedSaleable(true)
                    } else if (e.target.value == 'No') {
                      setSelectedSaleable(false)
                    }
                  }}>
                    <MenuItem value={''}>
                      <em className={classes['no-selection']}>Seleccionar</em>
                    </MenuItem>
                    <MenuItem className={classes['select-option']} key={'Si'} value={'Si'}>
                  Si
                </MenuItem>
                <MenuItem className={classes['select-option']} key={'No'} value={'No'}>
                  No
                </MenuItem>
                  </Select>
                </FormControl>

                <span className={classes['setting-attr-title']} >Stock Transferible*</span>

                <FormControl className={classes['select-filter']} size="small">
                  <Select value={selectedTransfer ? 'Si' : selectedTransfer == false ? 'No': ''}  
                    onChange={(e) => {
                    if (e.target.value == 'Si') {
                      setSelectedTransfer(true)
                    } else if (e.target.value == 'No') {
                      setSelectedTransfer(false)
                    }
                  }}>
                    <MenuItem value={-1}>
                      <em className={classes['no-selection']}>Seleccionar</em>
                    </MenuItem>
                    <MenuItem className={classes['select-option']} key={'Si'} value={'Si'}>
                  Si
                </MenuItem>
                <MenuItem className={classes['select-option']} key={'No'} value={'No'}>
                  No
                </MenuItem>
                  </Select>
                </FormControl>

                <span className={classes['setting-attr-title']} >Solicitar Stock*</span>

                <FormControl className={classes['select-filter']} size="small">
                  <Select value={selectedRequest ? 'Si' : selectedRequest == false ? 'No': ''}  
                    onChange={(e) => {
                    if (e.target.value == 'Si') {
                      setSelectedRequest(true)
                    } else if (e.target.value == 'No') {
                      setSelectedRequest(false)
                    }
                  }}>
                    <MenuItem value={-1}>
                      <em className={classes['no-selection']}>Seleccionar</em>
                    </MenuItem>
                    <MenuItem className={classes['select-option']} key={'Si'} value={'Si'}>
                  Si
                </MenuItem>
                <MenuItem className={classes['select-option']} key={'No'} value={'No'}>
                  No
                </MenuItem>
                  </Select>
                </FormControl>

                <span className={classes['setting-attr-title']}>Tipo de Reserva*</span>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={selectedReserveType ? selectedReserveType?.id!.toString() : null}
                onChange={(e) => setSelectedReserveType(dataReserveTypes?.results.find(reserve => reserve.id!.toString() === e.target.value))}
              >
                <MenuItem value={''}>
                  <em className={classes['no-selection']}>Seleccionar</em>
                </MenuItem>
                {dataReserveTypes?.results.map((e)=>(
                  <MenuItem className={classes['select-option']} key={e.id} value={e.id!.toString()}>
                  {e.description}
                </MenuItem>
              ))}
                
                
              </Select>
            </FormControl>

                <span className={classes['setting-attr-title']}>Tipo*</span>
            <FormControl className={classes['select-filter']} size='small'>
              <Select
                value={selectedWarehouseType ? selectedWarehouseType?.id.toString() : newEditItem?.warehouseType.id.toString()}
                onChange={(e) => setSelectedWarehouseType(dataWarehouseTypes?.find(warehouse => warehouse.id.toString() === e.target.value))}
              >
                <MenuItem value={''}>
                  <em className={classes['no-selection']}>Seleccionar</em>
                </MenuItem>
                {dataWarehouseTypes?.map((e)=>(
                  <MenuItem className={classes['select-option']} key={e.id} value={e.id.toString()}>
                  {e.description}
                </MenuItem>
              ))}
                
                
              </Select>
            </FormControl>

                <div className={classes['buttons-container']}>
                    <Button variant="contained" className={classes['btn-newsan-cancel']} onClick={()=>{setShowForm(false); setIsEdit(false); setNewEditItem(undefined); formatForm()}}>Cancelar</Button>
                    <Button variant="contained" className={isEdit ? classes['button-newsan-red'] : classes['button-newsan-grey']} onClick={async () => await saveFormInfo()}>{isEdit ? 'Guardar' : 'Cargar'}</Button>
                </div>
            </div>
        </div>
      :(<span className={classes['btn-show-form']} onClick={()=>setShowForm(!showForm)}>{NewsanIcons.ADD_STORE}</span>)    
    }
    </div>
    {
        showPopup ? (<PopupSuccessError
          open={showPopup}
          onClose={() =>{ 
            setShowPopup(false);
            setIsEdit(false)
            setNewEditItem(undefined)
            setDataResponse(null)
            setBaseEndpoint(errorResponse == null ? '' : baseEndpoint)
            setErrorResponse(null)
          }}
          loading={loadingResponse}
          success={dataResponse !== null}
          successMessage={dataResponse}
          errorMessage={errorResponse}
        />)
      :
      <></>
      }
    </div>
    </>
  )
  
}


export default WarehousePage;