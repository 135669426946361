/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@mui/material';
import classes from './Wizard.module.css'

type WizardProps = {
    btnEndDisabled: boolean;
    btnNextDisabled: boolean;
    onNext():void;
    btnCancelDisabled?: boolean;
    btnBackDisabled: boolean;
    cancelBackBtnLbl?: string;
    onBack():void;
    children?: any;
}


const WizardStep = ({btnEndDisabled, btnNextDisabled ,onNext ,btnBackDisabled, btnCancelDisabled=true, cancelBackBtnLbl ,onBack, children}: WizardProps) => {

    return <>
            {children}

            <div style={{width: '100%', textAlign: 'center'}}>
                <Button className={classes.formButtonBack} onClick={onBack} disabled={btnBackDisabled && btnCancelDisabled}>{cancelBackBtnLbl ?? 'Volver'}</Button>
                <Button className={classes.formButtonNext} onClick={onNext} disabled={btnNextDisabled && btnEndDisabled}> {btnEndDisabled ? 'Siguiente' : 'Finalizar'}</Button>
            </div>
    </>
}


export default WizardStep;