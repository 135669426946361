import React, { useEffect, useState } from 'react';
import { FormControl, SvgIcon } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import './CheckboxList.css'
import { CodeDescription } from '../../../model/models-module';
import {ReactComponent as CheckboxIcon} from '../../../assets/icons/checkbox.svg';

export enum CheckboxValueField {
  ID, CODE
}

interface CheckboxListProps {
    listOptions: CodeDescription[];
    title?: string;
    allOption?: boolean;
    multiple?: boolean;
    selectedOptions?: string[];
    valueField?: CheckboxValueField;
    setSelectedOptions(newData: string | string[]): void;
}

const CheckboxList = ({listOptions, selectedOptions, multiple = true, setSelectedOptions, valueField=CheckboxValueField.ID }: CheckboxListProps) => {
  const [selectedOptionsIds, setSelectedOptionsIds] = useState<string[]>(selectedOptions ?? []);

    useEffect(() => {
      if(selectedOptions) {
        // Valido la longitud para evitar que siga refrescando la seleccion.
        if(selectedOptionsIds.length != selectedOptions.length)
        setSelectedOptionsIds(selectedOptions);
      }
    }, [ selectedOptions, setSelectedOptionsIds])

    const handleOptionsChange = (selectedOptionId:string) => {
        let newList: string[] = [];
        if (selectedOptionId === 'all') {
          if (!selectedOptionsIds.includes('all')) {
            if(valueField === CheckboxValueField.ID) {
              newList = listOptions.map(lo=>lo.id.toString());
            } else {
              newList = listOptions.map(lo=>lo.code!);
            }
            newList.push('all')

          }
        } else {
          if(selectedOptionsIds.findIndex(a=> a === selectedOptionId) >= 0) {
            newList = selectedOptionsIds.filter(a=>a != selectedOptionId);
          } else {
            if(multiple) {
              newList = Object.assign([], selectedOptionsIds);
            }
            newList.push(selectedOptionId);
          }
        }

        setSelectedOptionsIds(newList);
        // Valido si existe una funcion de onChange
        if(setSelectedOptions) {
          setSelectedOptions(multiple ? (newList.length > 0 ? newList : '') : (newList.length > 0 ? newList[0] : ''));
        }
      }

      const clearFilter = () =>{
        setSelectedOptions('');
        setSelectedOptionsIds([]);
      }

    return(
        <div key={`Checklist-${Math.random()}`}>
          <FormControl size='small' className='check-container-padding' key={'control-check'}>

                {selectedOptionsIds.length != 0 ?
                <div className='check-list-container' key={'clear-container'}>
                  <span className='clear-container' key={'clear-button'}>
                    <SvgIcon component={CloseIcon} cursor={'pointer'} onClick={()=>clearFilter()}></SvgIcon>
                  </span>
                  <span className='clear-label' key={'clear-label'}>Borrar filtro</span>
                </div>: null}

                {listOptions.map(option => (
                  <div key={`${valueField==CheckboxValueField.ID ? option.id : option.code}-container`} className='check-list-container'>
                      <span className='check-container' key={`${valueField==CheckboxValueField.ID ? option.id : option.code}-check-container`}>
                          <SvgIcon onClick={()=>{handleOptionsChange(valueField === CheckboxValueField.ID ? option.id.toString():  option.code!)}} key={`${valueField==CheckboxValueField.ID ? option.id : option.code}-check`}
                                className={ selectedOptionsIds.findIndex(id=>id === (valueField === CheckboxValueField.ID ? option.id.toString():  option.code!)) >= 0 ? ' checked' : 'unchecked'} component={CheckboxIcon} viewBox="0 0 18 22" />
                      </span>
                      <span className='check-label' key={`${valueField==CheckboxValueField.ID ? option.id : option.code}-check-label`}>{option.description}</span>
                  </div>
                ))}

              {/* <FormGroup>
                  {listOptions.map(option => (
                      <FormControlLabel
                          className='setting-checkbox-item'
                          key={option.id.toString()}
                          control={<Checkbox
                            onChange={handleOptionsChange}
                            value={option.id.toString()} 
                            disabled={selectedOptionsIds.includes('all')}
                            checked={selectedOptionsIds.includes(option.id.toString())}
                            />}
                          labelPlacement="end"
                          label={option?.description} />
                  ))}
                  {allOption ? <FormControlLabel
                      className='setting-checkbox-item'
                      control={<Checkbox
                          checked={selectedOptionsIds.includes('all')}
                          onChange={handleOptionsChange}
                          value="all" />}
                      label="Todas"
                      labelPlacement="end" /> : <></>}
              </FormGroup> */}
          </FormControl>
        </div>
    )
}

export default CheckboxList