/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react'
import WizardStep from '../../../../components/common/wizard/WizardStep';
import { NewReserveDTO } from '../../../../model/dto-module';
import { TableDetailCell, TableHeaderCell, WarehouseReservesTypeDistribution } from '../../../../model/models-module';
import DataTable from '../../../../components/common/datatable/Datatable';
import classes from '../NewReservation.module.css'

type Props = {
  value: NewReserveDTO;
  onAfterSubmit(values: any): void; 
  onAfterBack(): void;
  list: WarehouseReservesTypeDistribution[];

}

const dataHeader: TableHeaderCell[] = [
  {label: 'Tipo Reserva', key: 'reserveType.description', sorteable: false, minWidth: 100, colStyle:{position:'relative', top: '5px'}},
  {label: 'Warehouse', key: 'warehouse.description', sorteable: false, minWidth: 140, colStyle:{position:'relative', top: '5px'}},
  {label: '%', key: 'distribution', sorteable: false, minWidth: 80, align: 'center', colStyle:{  textAlign: 'center'}, headerStyle: { textAlign: 'center', position: 'relative', right: '8px' }},
  {label: 'Cantidad', key: 'qty', sorteable: false, minWidth: 80, headerStyle: { textAlign: 'center', position: 'relative', right: '8px' }},
];

const NewReserveStepTwo = ({value, onAfterSubmit, onAfterBack, list = []}: Props) =>{
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]);

  useMemo(()=>{
    const aux: TableDetailCell[] = [];
    list.forEach((v)=>{
      console.warn(value.stockReserved!, v.distribution!, 100);
      aux.push(
        new TableDetailCell(v, undefined, 
          new Map<string, React.ReactNode>([
            ['qty', <>{ Math.round(value.stockReserved! * v.distribution! / 100)}</>]
          ])
          )
      );
    });
    setDataShow(aux);

  },[list])
  
    return (
      <WizardStep onNext={()=> { onAfterSubmit(value);}} onBack={()=>{onAfterBack()}} btnBackDisabled={false} btnNextDisabled={false} btnEndDisabled={true}>
        <div className={`${classes.formContainer} nwsn-scroll`}>
          <DataTable headers={dataHeader}  rows={dataShow}
                  countItems={dataShow.length}  rowsPerPage={100} hasActions={false} groupActions={false}
                  appendOnScrollToBottom={true} onScrollToBottom={(pageSize: number)=>console.warn(pageSize)} ></DataTable>
        </div>
    </WizardStep>
            )
}


export default NewReserveStepTwo;