/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';
import 'moment/locale/es';

moment.locale('de');

const NotifDateFormatter = (date: Date) => {
    const aux = moment(date).format('DD MMM');
    let dateFormatted = aux.replace(/\d{2}\s(\w).*/g, '$1').toLocaleUpperCase();
    dateFormatted = aux.replace(/(\d{2}\s)\w.*/g, '$1') + dateFormatted + aux.replace(/\d{2}\s\w(.*)/g, '$1');
    return dateFormatted;
}


const formatParamDate = (date?: Date) => {
    if(date) {
        const aux = moment(date).format('yyyy-MM-DD');
        return aux;
    }
    return null;
}

const formatDate = (date?: Date) => {
    if(date) {
        const aux = moment(date).format('DD/MM/yyyy');
        return aux;
    }
    return null;
}

const extractHierachyValue = ({data, props}: {data:any, props: string}):any => {
    let result = null;
    if(props !== undefined && props !== null) {
        if(props.includes('.')) {
            const dotIndex = props.indexOf('.');
            result = data[props.substring(0, dotIndex)];
            return extractHierachyValue({data: result, props: props.substring(dotIndex+1)});
        } else {
            result = data ? data[props] : null;
        }
    }

    return result;

}



  const appendStringParam = (value: string, paramCode:string) =>{
    let param: string = '';

    if(value) {
      param += `${paramCode}=${value}`;
    }

    return param;
  }

  const appendNumberParam = (value: number, paramCode: string) =>{
    let param: string = '';

    if(value > 0) {
      param += `&${paramCode}=${value}`;
    }

    return param;
  }

  const appendDateParam = (value?: Date, paramCode?: string) =>{
    let param: string = '';
    if(value) {
      param += `&${paramCode}=${formatParamDate(value)}`;
    }

    return param;
  }

  const addParam = (value: any, valueName: string, params: Map<string,string>, baseEndpoint: string, setEndPoint: any) => {
    if(value) {
      params.set(valueName, appendStringParam(value,valueName));
    } else {
      params.delete(valueName);
    }
    let aux = '';
    params.forEach((valueArg) => {
      if(aux) {
        aux += `&${valueArg}`;
      } else {
        aux += `?${valueArg}`;
      }
    });
    setEndPoint(baseEndpoint + aux);
  }


export {
    NotifDateFormatter,
    extractHierachyValue,
    formatDate,
    formatParamDate,
    appendStringParam,
    appendNumberParam,
    appendDateParam,
    addParam,
};