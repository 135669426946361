import { IconButton, Snackbar, Tooltip } from '@mui/material'
import { useState } from 'react'
import NewsanIcons from './Icons'

const ButtonToCopy = ({text, style}: {text: string, style?: React.CSSProperties}) => {
    const [open, setOpen] = useState(false);

    function unsecuredCopyToClipboard() {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
      } catch (err) {
        console.error('Unable to copy to clipboard', err);
      }
      document.body.removeChild(textArea);
    }

    const handleClick = () => {
      setOpen(true)
      if (window.isSecureContext && navigator.clipboard) {
        navigator.clipboard.writeText(text);
      } else {
        unsecuredCopyToClipboard();
      }
    }
    
    return (
        <>
        <Tooltip title={'Copiar'}>
        <IconButton onClick={handleClick} style={style}>{NewsanIcons.COPY}</IconButton>
        </Tooltip>
          <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={2000}
            message="Copiado!"
            ContentProps={{
              style: { minWidth: '10px'}
            }}
          />
        </>
    )
}

export default ButtonToCopy