const sizeConfigs = {
  sidebar: {
    width: '250px',
    marginTop: '15px',
    borderRadius: '8px',
  },
  topbar: {
    heigth: '64px',
  },
  main: {
    cutHeight: '43px'
  }
}

export default sizeConfigs
