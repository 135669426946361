/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService from './BaseService';

interface QueryParamsWarehouse {
    dataTo?: number;
    dataOffset?: number;
    typeReserveType?: string;
    
}

export const NORMAL = 'Normal';
export const COMBO = 'Combo';

export default class WarehouseService extends BaseService {


    private static _instance: WarehouseService;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }


    async findAll({dataTo, dataOffset = 0, typeReserveType}: QueryParamsWarehouse = {}) {
        
        try {   
            let url = `/warehouses?offset=${dataOffset}`;

            if(dataTo) {
                url += `&limit=${dataTo}`;
            }
            if(typeReserveType) {
                url += `&typeReserveType=${typeReserveType}`
            }
            const response = await this.client({
                url,
                method: 'GET'
            });

            return response.data.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
        
    }    


}