import React, { useState } from 'react';
import NewsanIcons from '../../Icons';
import classes from './NotificationCard.module.css'
import { NotifDateFormatter } from '../../../../utils/FormatterUtils';
import { Tooltip, Typography } from '@mui/material';
import AxiosInterceptor from '../../../../services/http/AxiosInterceptor';
import axios from 'axios';

type Notification = {
    id: number;
    title: string;
    message: string;
    date: string;
    type: string;
    criticality: string;
    iconColor: string | null;
    onClick():void;
}

const client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL)

const NotificationCard = (notif: Notification)=> {
    const [loadingResponse, setLoadingResponse] = useState(false);
    const [errorResponse, setErrorResponse] = useState<string | null>(null);
    const [dataResponse, setDataResponse] = useState<string | null>(null);

    if(errorResponse) console.warn(errorResponse)
    if(loadingResponse) console.warn(loadingResponse)
    if(dataResponse) console.warn(dataResponse)

    const fetchData = async () => {
        try{
          setLoadingResponse(true)
            await client.patch(`/notifications/${notif?.id}`, {'status': 'read'}).then(()=> {
              setDataResponse('Modificado con éxito!')
              setErrorResponse(null)
            }).catch((error) => {
              if (error.response.status === 400) {
                setErrorResponse(error.response.data.message[0])
              }
            })
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setErrorResponse(error.response?.data.message);
          } else if (error instanceof Error) {
            setErrorResponse(error.message);
          } else {
            setErrorResponse('Hubo un error en la solicitud');
          }
        } finally {
          setLoadingResponse(false);
        } 
      }


    return (
        <div className={classes['container']}>
                    <Tooltip title={notif.type + ' - ' + notif.criticality} key={notif.id} className={classes[notif.iconColor!]}>
                        {notif.type == 'info' ? NewsanIcons.INFO_OUTLINED : NewsanIcons.WARNING}
                    </Tooltip>
                    <div className={classes['contentColumn']} >
                        <Typography fontSize={'14px'} fontWeight={'bold'} className={classes['titleContainer']}>{notif.title}</Typography>
                        <Typography fontSize={'13px'} fontWeight={'bold'} className={classes['describeContainer']}>{notif.message}</Typography>
                        <span className={classes['dateContainer']}>{NotifDateFormatter(new Date(notif.date))}</span>
                    </div>
                    <div className={classes['deleteIcon']}>
                         <span onClick={(e)=>{ e.stopPropagation(); e.preventDefault(); fetchData(); notif.onClick()}}>{NewsanIcons.CANCEL_ERROR_OUTLINED}</span>
                    </div>
        </div>
    );
}


export default NotificationCard;