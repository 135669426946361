import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import colorConfigs from '../../configs/colorConfigs'
import { RouteType } from '../../routes/config'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import SidebarItem from './SidebarItem'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store';

type Props = {
  item: RouteType;
  drawerExpanded: boolean;
}

const SidebarItemCollapse = ({ item, drawerExpanded }: Props) => {
  const [open, setOpen] = useState(false)

  const { appState } = useSelector((state: RootState) => state.appState)

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true)
    }
  }, [appState, item])

  return item.sidebarProps ? (
    <>
      <ListItemButton
        onClick={() => setOpen(!open)}
        className='newsan-sidebar-item'
        sx={{
          backgroundColor: 'transparent',
        }}
      >
        <ListItemIcon
          sx={{
            color: colorConfigs.sidebar.color,
          minWidth: '35px'
          }}
        >
          {item.sidebarProps.icon && item.sidebarProps.icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={<Typography>{drawerExpanded ? item.sidebarProps.displayText : ''}</Typography>}
        />
        {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' >
        <List style={{marginLeft: '20px', paddingTop: 0, paddingBottom: 0}}>
          {item.child?.map((route, index) =>
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} drawerExpanded={drawerExpanded}/>
              ) : (
                <SidebarItem item={route} key={index} drawerExpanded={drawerExpanded}/>
              )
            ) : null,
          )}
        </List>
      </Collapse>
    </>
  ) : null
}

export default SidebarItemCollapse
