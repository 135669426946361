/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// import useData from '../../components/hooks/useData'
import Topbar from '../../components/common/topbar/Topbar'
import { CodeDescription, FilterTable, FilterTableType, MovementReport, ReservedType, TableAction, TableDetailCell, TableHeaderCell, Warehouse } from '../../model/models-module'
import { addParam } from '../../utils/CommonUtils'
import {useData} from '../../components/hooks/useData'
import DataTable from '../../components/common/datatable/Datatable'
import { CircularProgress, Tooltip, Typography } from '@mui/material'
import classes from './MovementReports.module.css'
import { GridDTO } from '../../model/dto-module'
import ButtonToCopy from '../../components/common/ButtonToCopy'
import { useSearchParams } from 'react-router-dom'
import NewsanIcons from '../../components/common/Icons'
import WarehouseStockMovementService from '../../services/WarehouseStockMovementService'


// const gridActions = 
const MovementReports = () => {

  const [searchParams] = useSearchParams();
  const pTransactionId = searchParams.get('transactionId');

  const [headerActions, setHeaderActions] = useState<TableAction[]>(); 
  const [loadingHeader, setLoadingHeader] = useState<boolean>(true);
  const [loadingReprocess, setLoadingReprocess] = useState<boolean>(false);
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([]);
  const [dataTo, setDataTo] = useState<number>(50);
  const [countItems, setCountItems] = useState<number>(0);
  const [dataOffset, setDataOffset] = useState<number>(0);
  const [sku, setSku] = useState<string>();
  const [date, setDate] = useState<string>();
  const [transactionId, setTransactionId] = useState<string>(pTransactionId ?? '');
  const [status, setStatus] = useState<string>();
  const [distributionCentre, setDistributionCentre] = useState<string>();
  const [warehouse, setWarehouse] = useState<string>();
  const [reserveType, setReserveType] = useState<string>();
  const [movementType, setMovementType] = useState<string>();
  const [user, setUser] = useState<string>();
  const [service] = useState<WarehouseStockMovementService>(WarehouseStockMovementService.Instance);


  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]);
  const [page, setPage] = useState<number>(1);

  const [baseEndpoint, setBaseEndpoint] = useState<string>('/warehouses-stock-movements');  
  const [endpoint, setEndPoint] = useState<string>('');  
  const [loading, error, dataGrid] = useData<GridDTO<MovementReport>>(endpoint, 'GET');
  const [params] = useState<Map<string,string>>(new Map());  

  const [loadingReservedTypes, errorReservedTypes, dataGridReservedTypes] = useData<GridDTO<ReservedType>>('/reserved-types', 'GET');
  const [loadingDistributionCentres, errorDistributionCentres, dataGridDistributionCentres] = useData<CodeDescription[]>('distribution-centres', 'GET');
  const [loadingWarehouses, errorWarehouses, dataWarehouses] = useData<GridDTO<Warehouse>>('/warehouses', 'GET')
  const [loadingStates, errorStates, dataStates] = useData<string[]>('warehouse-stock-movement-status', 'GET')
  const [loadingTypes, errorTypes, dataTypes] = useData<CodeDescription[]>('movement-types', 'GET')

  const formatearFecha = (date: string) => {
    const fechaObj = new Date(date.replace('Z', ''));
    
    const año = fechaObj.getFullYear();
    const mes = (fechaObj.getMonth() + 1).toString().padStart(2, '0');
    const dia = fechaObj.getDate().toString().padStart(2, '0');
    const hora = fechaObj.getHours().toString().padStart(2, '0');
    const minutos = fechaObj.getMinutes().toString().padStart(2, '0');
    
    return `${dia}/${mes}/${año} ${hora}:${minutos}`;
  }

  if(error || errorReservedTypes || errorDistributionCentres || errorWarehouses || errorStates || errorTypes) {
    console.error(error, errorReservedTypes, errorDistributionCentres, errorWarehouses, errorStates, errorTypes);
  }

  useEffect(()=> {
    addParam(dataTo, 'limit', params, baseEndpoint, setEndPoint);
  }, [dataTo, baseEndpoint])


  useEffect(()=> {
      addParam(dataOffset, 'offset', params, baseEndpoint, setEndPoint);
  }, [dataOffset, baseEndpoint])

  useEffect(()=> {
      setDataOffset(0)
      addParam(sku, 'sku', params, baseEndpoint, setEndPoint);
  }, [sku, baseEndpoint]);

  useEffect(()=> {
      setDataOffset(0);
      const partes = date?.split('/');
      const fechaTransformada = partes?.join('');
      addParam(fechaTransformada, 'date', params, baseEndpoint, setEndPoint);
  }, [date, baseEndpoint]);

  useEffect(()=> {
      setDataOffset(0);
      addParam(reserveType, 'reserveType', params, baseEndpoint, setEndPoint);
  }, [reserveType, baseEndpoint]);

  useEffect(()=> {
      setDataOffset(0)
      addParam(distributionCentre, 'distributionCentre', params, baseEndpoint, setEndPoint);
  }, [distributionCentre, baseEndpoint]);

  useEffect(()=> {
      setDataOffset(0)
      addParam(warehouse, 'warehouse', params, baseEndpoint, setEndPoint);
  }, [warehouse, baseEndpoint]);

  useEffect(()=> {
    setDataOffset(0)
    addParam(status, 'status', params, baseEndpoint, setEndPoint);
  }, [status, baseEndpoint]);

  useEffect(()=> {
    setDataOffset(0)
    addParam(movementType, 'movementTypeId', params, baseEndpoint, setEndPoint);
  }, [movementType, baseEndpoint]);

  useEffect(()=> {
    setDataOffset(0)
    addParam(transactionId, 'transactionId', params, baseEndpoint, setEndPoint);
  }, [transactionId, baseEndpoint]);

  useEffect(()=> { 
    setDataOffset(0)
    addParam(user, 'creator', params, baseEndpoint, setEndPoint);    
}, [user, baseEndpoint]);

  useEffect(()=> {
    let filterLoadeed = !loadingReservedTypes && dataGridReservedTypes != null &&  dataHeader.length === 0;
    filterLoadeed = filterLoadeed && !loadingDistributionCentres && dataGridDistributionCentres != null;
    filterLoadeed = filterLoadeed && !loadingWarehouses && dataWarehouses?.results != null;
    filterLoadeed = filterLoadeed && !loadingStates && dataStates != null;
    filterLoadeed = filterLoadeed && !loadingTypes && dataTypes != null;

  if(filterLoadeed ) {
    setDataHeader([
      new TableHeaderCell({label: 'Fecha', key: 'date', sorteable: false, filter: new FilterTable({onChange: (newVal: any)=>{ setDate(newVal)}, placeholder: 'AAAA/MM/DD'  }), headerStyle:{minWidth: '140px'}, colStyle: {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '120px'}}),
      new TableHeaderCell({label: 'Usuario', key: 'user', sorteable: false, filter: new FilterTable({onChange: (newVal: any)=>{ setUser(newVal)}, placeholder: 'Busca aqui...'  }), }),
      new TableHeaderCell({label: 'Transacción', key: 'transactionId', sorteable: false, filter: new FilterTable({value: transactionId, onChange:  (newVal: any)=>{ setTransactionId(newVal)}, placeholder: 'Busca aqui...'  }), headerStyle:{minWidth: '160px'}, colStyle: {maxWidth: '160px'}}),
      new TableHeaderCell({label: 'SKU', key: 'sku', sorteable: false, filter: new FilterTable({onChange: (newVal: any)=>{ setSku(newVal)}, placeholder: 'Busca aqui'  })}), 
      new TableHeaderCell({label: 'Estado', key: 'status', sorteable: false, filter: new FilterTable({ onChange:  (newVal: any)=>{ setStatus(newVal)}, useCodeField:true, data: dataStates! ? dataStates.map((rt, i)=>{
        return {id: i+1, code: rt, description: rt};
      }) : [], type: FilterTableType.MULTIPLE})}),
      new TableHeaderCell({label: 'Tipo', key: 'movementType', sorteable: false, filter: new FilterTable({ onChange:  (newVal: any)=>{ setMovementType(newVal)}, useCodeField:false, data: dataTypes! ? dataTypes.map((rt)=>{
        return rt
      }) : [], type: FilterTableType.MULTIPLE})}),
      new TableHeaderCell({label: 'CD', key: 'distributionCentre', sorteable: false, filter: new FilterTable({onChange: (newVal: any)=>{ console.warn(newVal); setDistributionCentre(newVal)}, useCodeField:true, data: dataGridDistributionCentres! ? dataGridDistributionCentres.map((rt)=>{
        rt.code = rt.description;
        return rt;
      }) : [], type: FilterTableType.COMBO})}),
      new TableHeaderCell({label: 'Tipo reserva', key: 'reserveType', sorteable: false, filter: new FilterTable({onChange: (newVal: any)=>{console.warn(newVal); setReserveType(newVal)}, useCodeField:true, data: dataGridReservedTypes!.results! ? dataGridReservedTypes!.results.map((rt, i)=>{
        return {id: i+1, code: rt.code, description: rt.code};
      }) : [], type: FilterTableType.MULTIPLE}), colStyle: {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '120px', position: 'relative', top: '0px'}}),
      new TableHeaderCell({label: 'Almacén', key: 'warehouse', sorteable: false, filter: new FilterTable({onChange:  (newVal: any)=>{console.warn(newVal); setWarehouse(newVal)}, useCodeField:true, data: dataWarehouses ? dataWarehouses.results.map((rt)=>{
        rt.code = rt.description;
        return rt;
      }) : [], type: FilterTableType.MULTIPLE}), colStyle: {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', minWidth: '120px', position: 'relative', top: '0px'}}),
      new TableHeaderCell({label: '#', key: 'quantity', sorteable: false, headerStyle: {minWidth: '100px', textAlign: 'center', fontSize:'22px'}, colStyle: {textAlign: 'center'}}),

    ]);
    setLoadingHeader(false);
  }
  }, [transactionId, loadingReservedTypes, dataGridReservedTypes, dataHeader, loadingDistributionCentres, dataGridDistributionCentres, loadingWarehouses, dataWarehouses, loadingStates, dataStates])

  function reloadTable() {
    setBaseEndpoint('')
    setEndPoint('')
  }
  
  useEffect(()=>{
    if(!loading && dataGrid) {
      setCountItems(dataGrid.count);
      setDataShow(dataGrid.results.map((r) => {
        return new TableDetailCell({...r, date: formatearFecha(r.date), user: r.changedBy ?? r.createdBy},
            [],
            new Map<string, React.ReactNode>([
              ['sku',
              <div key={r.sku + 'sku'} className={classes['textWithCopy']} style={{minWidth:'115px'}}>
                {r.sku! && r.sku.length < 10 ? <Typography fontSize={'0.875rem'} style={{paddingRight: '40px'}}>{r.sku}</Typography>: <Tooltip title={r.sku}><Typography  whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'} fontSize={'0.875rem'} maxWidth={85}>{r.sku}</Typography></Tooltip>}
                {r.sku != null ? <ButtonToCopy text={r.sku} style={{position: 'absolute', right: '0px', top: '-10px'}}></ButtonToCopy>: null}
              </div>
              ],
              ['transactionId',
              <div key={r.transactionId + 'transaction-id'} className={classes['textWithCopy']} style={{maxWidth:'135px'}}>
                {r.transactionId! && r.transactionId.length < 14 ? <Typography fontSize={'0.875rem'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>{r.transactionId}</Typography> : <Tooltip title={r.transactionId}><Typography  whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'} fontSize={'0.875rem'} maxWidth={105}>{r.transactionId}</Typography></Tooltip>}
                {r.transactionId != null && r.transactionId != '' ? <ButtonToCopy text={r.transactionId} style={{position: 'absolute', right: '0px', top: '-10px'}}></ButtonToCopy> : null}
              </div>
              ],
              ['status',
                <span key={`restart-${r.id}`} className={r.status! && r.status.toLocaleLowerCase() === 'error' && r.movementType! && r.movementType.toLocaleLowerCase() === 'venta' ? classes.restartIconCont: classes.statusWithoutIcon}>
                  {r.status}
                  {
                    r.status! && r.status.toLocaleLowerCase() === 'error'
                    && r.movementType! && r.movementType.toLocaleLowerCase() === 'venta' ?
                      <span className={classes.restartIcon} onClick={()=>{ setLoadingReprocess(true); service.restartSale(r.transactionId).finally(async ()=>{setLoadingReprocess(false); await reloadTable()})}}>
                        {NewsanIcons.RESTART}
                      </span>
                    : null
                  }
                </span>
              ],
              ['reserveType',
                r.reserveType! && r.reserveType.length < 14 ? <Typography fontSize={'0.875rem'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>{r.reserveType}</Typography> : <Tooltip title={r.reserveType}><Typography  whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'} fontSize={'0.875rem'} maxWidth={105}>{r.reserveType}</Typography></Tooltip>
              ],
              ['warehouse',
                r.warehouse! && r.warehouse.length < 14 ? <Typography fontSize={'0.875rem'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'}>{r.warehouse}</Typography> : <Tooltip title={r.warehouse}><Typography  whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'} fontSize={'0.875rem'} maxWidth={105}>{r.warehouse}</Typography></Tooltip>
              ]
            ]),
            classes['customRow'])
      }))
    }
  }, [setDataShow, dataGrid, loading]);

  const onChangePageSize = useCallback((size: number) => {setDataTo(size); setDataOffset(0); setPage(1);},[])
  const onChangePage = useCallback((page: number)=>{ setPage(page); setDataOffset(page-1) },[])

  useEffect(()=> {
    if (baseEndpoint == '') setBaseEndpoint('/warehouses-stock-movements')
  }, [dataTo, baseEndpoint])


  useMemo(()=>{
    const restartSales = new TableAction({label:'Reprocesar ventas con error', action: () => {
            setLoadingReprocess(true);
            service.restartSalesWithError().finally(async ()=>{setLoadingReprocess(false); await reloadTable();})
          }, icon: NewsanIcons.REFRESH});

    setHeaderActions([restartSales]);
  }, [])

  return (
    <div className={classes.movementPage}>
      {
        loading || loadingHeader || loadingReprocess ?
        <div className='loadingPage'>
          <CircularProgress className='circleProgress'/>
        </div>
        : null
      }
        <Topbar title={'Monitor de movimientos'} hasReloadIcon reloadPage={reloadTable}/>
      <div className={classes['container-abm-page']}>
        <div className={classes['table-templates-container']}>
          {
          !loadingHeader ?
            <DataTable 
            headerGroupActionsIcon={NewsanIcons.PLUS}
            headerActions={headerActions}
            headers={dataHeader}
            rows={dataShow}
            countItems={countItems}
            rowsPerPage={dataTo}
            hasActions={true}
            appendOnScrollToBottom={false}
            onPageSizeChange={onChangePageSize}
            onPageChange={onChangePage}
            pageSelected={page}
            pageable={true}
            selectable={false}
            >
            </DataTable>
            : null
            }
        </div>
      </div>
      <></>
    </div>
  )
}

export default MovementReports;
