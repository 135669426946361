/* eslint-disable @typescript-eslint/no-explicit-any */
import {Popover, Typography } from '@mui/material'
import React from 'react';
import classes from './TextWithPopover.module.css'

interface Props{
    text: string,
    popoverText: string,
}


const TextWithPopover = ({text, popoverText}: Props) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

return  (<div>
    <Typography
        className={classes.link}
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        fontSize={'0.875rem'}
        whiteSpace={'nowrap'}
    >
        {text}
    </Typography>
    <Popover
        id="mouse-over-popover"
        sx={{
        pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
    >
        <Typography sx={{ p: 1 }} className={classes.paper}>{popoverText}</Typography>
    </Popover>
</div>);
}

export default TextWithPopover