/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService from './BaseService';

export default class WarehouseStockMovementService extends BaseService {


    private static _instance: WarehouseStockMovementService;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    async restartSale(transactionId:string) {
        
        try {    
            const response = await this.client({
                url: `/warehouses-stock-movements/restart-sale/${transactionId}`,
                method: 'POST'
            });

            return response.data.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
        
    }

    async restartSalesWithError() {        
        try {    
            const response = await this.client({
                url: '/warehouses-stock-movements/restart-sale',
                method: 'POST'
            });

            return response.data.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
    }

    async findPendings(params: any) {
        const {idWarehouse, idReserveType, sku, distributionCentreCode} = params;
        let pQuery = '';
        if(idWarehouse) {
            pQuery += `&idWarehouse=${idWarehouse}`;
        }

        if(idReserveType) {
            pQuery += `&idReserveType=${idReserveType}`;
        }
        
        if(sku) {
            pQuery += `&sku=${sku}`;
        }
        
        if(distributionCentreCode) {
            pQuery += `&distributionCentreCode=${distributionCentreCode}`;
        }

        pQuery = pQuery.substring(1);
        
        try {    
            const response = await this.client({
                url: `/warehouses-stock-movements/find-pendings?${pQuery}`,
                method: 'GET'
            });

            return response.data.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
        
    }    


}