import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { store } from './redux/store'
import App from './App'
import UserService from './services/UserService'
import reportWebVitals from './reportWebVitals'
import './index.css'

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!
const root = createRoot(container)

const renderApp = () =>
  root.render(
    // React 18 useEffect called twice
    // https://stackoverflow.com/questions/72238175/why-useeffect-running-twice-and-how-to-handle-it-well-in-react
    // <React.StrictMode>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>,
    // </React.StrictMode>,
  )

UserService.initKeycloak(renderApp)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'production') {
  reportWebVitals()
}
