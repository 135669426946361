/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react'
import Topbar from '../../components/common/topbar/Topbar'
import DragDropFile from '../../components/common/drag-and-drop/DragAndDrop'
import DataTable from '../../components/common/datatable/Datatable'
import classes  from './StockLoadFile.module.css'
import { FileErrorDetail, FileErrorDetailMessage, FileResult, TableDetailCell, TableHeaderCell } from '../../model/models-module'
import FileStatusCard from './file-status-card/FileStatusCard'
import { LOCALE } from '../../constants/constants'
import { useData } from '../../components/hooks/useData'
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import axios from 'axios'
import Modal from '@mui/material/Modal';
import useModal from '../../components/hooks/useModal'
import { Button, CircularProgress, Tooltip, Typography } from '@mui/material'
import { toast } from 'react-toastify'
import StockService from '../../services/StockService'
import FileStatusService from '../../services/FileStatusService'
import UserService from '../../services/UserService'



const baseURL: string | undefined = process.env.REACT_APP_API_BASE_URL;

const downloadCSV = async (id?: number) => {

  if(id) {
    const client = axios.create({baseURL});
    const response = await client({
        url: `files/download/${id}`,
        method: 'GET',
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'blob',
          Authorization : `Bearer ${UserService.getToken()}`
        },
      });
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'registros_con_error.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
  }
  
}


const StockLoadFile = () => {

  const service: StockService = StockService.Instance;
  const serviceFile: FileStatusService = FileStatusService.Instance;
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]); 
  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([]); 
  const [filesResult, setFileResult] = useState<FileResult[]>([]);
  const [data, setData] = useState<FileResult>();
  const [modalData, setModalData] = useState<FileErrorDetailMessage>();
  const [statusUrl, setStatusUrl] = useState<string>('');
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const [loading, error, dataFiles] = useData<FileResult[]>(statusUrl, 'GET');
  const [loadingFR, errorFR, dataFilesFR] = useData<FileResult>(data ? `files/status/${data.id}` : null, 'GET');
  const { isOpen, toggle } = useModal();

  const notify = () => toast('El archivo se está procesando..');

  useMemo(()=>{
    if(dataHeader.length === 0) {
      setDataHeader([
        new TableHeaderCell({label: 'CD', key: 'col0', sorteable: true}), 
        new TableHeaderCell({label: 'SKU', key: 'col1', sorteable: true}),
        new TableHeaderCell({label: 'Descripción', key: 'col2'}),
        new TableHeaderCell({label: 'Tipo Reserva', key: 'col3', sorteable: true, headerStyle: {minWidth: '150px'}}),
        new TableHeaderCell({label: 'Reserva', key: 'col4', headerStyle: {minWidth: '125px'}, colStyle: {textAlign: 'center', marginLeft: '-18%'}}),
        new TableHeaderCell({label: 'Detalle error', key: 'col5'}),
      ]);
    }

  }, [dataHeader]);
  

  useEffect(()=>{
    if(statusUrl === '') {
      setStatusUrl('files/status?limit=20');
    }
    if(!loading && dataFiles) {
      setFileResult(dataFiles.map(df=>new FileResult(df)));
    }

  }, [loading, dataFiles, statusUrl]);


  useEffect(()=>{
    if(!loadingFR && dataFilesFR) {
      setDataShow(dataFilesFR.errorDetails!.map((pDg:FileErrorDetail)=>{
        const dg: FileErrorDetail = new FileErrorDetail(pDg);
        const dataRow:string[]= (dg.row ?? '').split('|');
        const auxData: any = {};
        dataRow.forEach((v, i)=>{
          auxData[`col${i}`] = v;
        })
        console.warn(auxData)
        return new TableDetailCell(auxData, undefined, 
          new Map<string, React.ReactNode>([
            ['col2', auxData.col2 != null && auxData.col2.length < 20 ? <Typography className={classes.productDescription} fontSize={'0.875rem'}>{auxData.col2}</Typography> : <Tooltip title={auxData.col2}><Typography className={classes.productDescription} fontSize={'0.875rem'}>{auxData.col2}</Typography></Tooltip>],
            ['col5', <Button className={classes.buttonError} key={`custom-${dg.id}`} sx={{display: 'flex', justifyContent: 'flex-start'}}  onClick={()=>{ setModalData(dg.rawMessageToData); toggle();}} >Ver Detalle</Button>],
          ]));
      }));
    }

  }, [loading, dataFilesFR]);
  
  if(error) {
    return <div>{error}</div>
  }

  return <>
  
    {
    showSpinner ?
      <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
      : null
    }
  <div className={classes.stockLoadPage}>
    <Topbar title={'Carga masiva'} >

        <div style={{textAlign: 'center'}}>
          <Button variant="contained" className='button-newsan-red' onClick={()=>{
            setShowSpinner(true);
            service.generateReport().then(
              (data: any)=>{
                const blob = new Blob([data]);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'reporte.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }
            ).finally(
              () => {
                setShowSpinner(false);
              }
            );
          }}>
            <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon> Generar Reporte
          </Button>
        </div>
    </Topbar>
    <div style={{
          marginTop: '30px',
          marginBottom: '22px',
          height: '41vh',
          minHeight: '240px',
          position: 'relative'
        }}>
      <div className={`${classes['side-upload']} ${classes.dragDropSide}`}>
        <DragDropFile url={'files/upload'} onChange={()=>{ 
          notify();
          setStatusUrl('');}}></DragDropFile>
      </div>
      <div className={classes['side-upload'] + ' ' + classes['result-side']}>
{/* 
        {
          data && data.id ?
            <div>
              <span className={`${classes['static-card']} ${classes.success}`}>
                <span className={classes['card-title']}>exitoso</span>
                <span className={classes['card-result']}>{data.rowsCount!.toLocaleString(LOCALE, {maximumFractionDigits:0})}</span>
              </span>
              <span className={`${classes['static-card']} ${classes.error}`}>
                <span className={classes['card-title']}>Error</span>
                <span className={classes['card-result']}>{data.errorsCount!.toLocaleString(LOCALE, {maximumFractionDigits:0})}</span>
                {
                  data.errorsCount && data.errorsCount > 0 ?
                    <FileDownloadSharpIcon className={classes['error-csv-download-icon']} onClick={()=>{downloadCSV(data.id)}}></FileDownloadSharpIcon>
                  : null
                }

              </span>
            </div>
          : null
        } */}
        <div className={`${classes['file-status-list-container']} nwsn-scroll`}>
        {filesResult.length != 0 ?  
          filesResult.map(
            (f) => <FileStatusCard onClick={setData} key={f.id} fileResult={f} onDelete={(f: FileResult)=>{ 
              setShowSpinner(true);
              serviceFile.delete(f.id!).then(()=>{
                toast.success('Se eliminó el registro exito.');
              }).finally(()=>{
                setShowSpinner(false);
                setStatusUrl('');
              });
            }} 
            selected= {(data ? data.id : 0) === f.id}></FileStatusCard>
          ) : 'REGISTRO DE ARCHIVOS'}
        </div>
        <div style={{ textAlign: 'left', marginBottom: '20px', paddingLeft: '35px', paddingRight: '18px', display: 'flex', justifyContent: 'center'}}>
            <span className={`${classes['static-card']} ${classes.success}`}>
              <span className={classes['card-title']}>exitoso </span>
              <span className={classes['card-result']}>{data ? data.rowsCount!.toLocaleString(LOCALE, {maximumFractionDigits:0}) : 0}</span>
            </span>
            <span className={`${classes['static-card']} ${classes.error}`}>
              <span className={classes['card-title']}>Error</span>
              <span className={classes['card-result']}>{data ? data.errorsCount!.toLocaleString(LOCALE, {maximumFractionDigits:0}) : 0}</span>
              {
                data && data.errorsCount && data.errorsCount > 0 ?
                  <FileDownloadSharpIcon className={classes['error-csv-download-icon']} onClick={()=>{downloadCSV(data.id)}}></FileDownloadSharpIcon>
                : null
              }

            </span>
        </div>
      </div>
    </div>
    {
      data && data.id && !loadingFR ?
      errorFR != null ? <b>{errorFR}</b> :
      <div className={`${classes['table-container-load']} nwsn-scroll`}>
        <DataTable headers={dataHeader}  rows={dataShow}  className={` ${classes['table-container']}`}
            countItems={dataShow.length}  rowsPerPage={100} ></DataTable>
      </div>
      : null
    }

    <div>
      
      <Modal open={isOpen} onClose={toggle}>
        <div className={classes.modalContainer}>
          <div style={{textAlign: 'center', height: '60%', padding: '14px'}}>
            {modalData ? modalData.message : ''}
          </div>
          <div style={{textAlign: 'center'}}>
          <Button onClick={()=>toggle()}>Cerrar</Button>
          </div>
        </div>

      </Modal>
    </div>
    </div>
    </>
}

export default StockLoadFile
