// import { ReactNode } from 'react'
import UserService from '../../services/UserService'

interface Props {
  children: JSX.Element
}

const RenderOnAuthenticated = ({ children }: Props) => (UserService.isLoggedIn() ? children : null);

export default RenderOnAuthenticated;
