

const colorConfigs = {
  sidebar: {
    bg: '#FFFFFF',
    color: '#34415a',
    hoverBg: '#1e293a',
    hoverColor: '#721c31',
    activeBg: '#1e253a',
  },
  topbar: {
    bg: '#fff',
    color: '#000',
  },
  mainBg: '#f5f5f5',
}

export default colorConfigs
