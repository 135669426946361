import moment from 'moment';
import 'moment/locale/es';

moment.locale('de');

export const NotifDateFormatter = (date: Date) => {
    const aux = moment(date).format('DD MMM YYYY HH:mm:ss');
    return aux;
}


export {};