/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService from './BaseService';

export default class FileStatusService extends BaseService {

    private static _instance: FileStatusService;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }
    

    async delete(id: number): Promise<void> {
        try {    
            await this.client({
                url: `/files/${id}`,
                method: 'DELETE'
            });

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
    }

}