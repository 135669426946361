/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosError, AxiosInstance, Method } from 'axios'
import { useEffect, useState } from 'react'

import AxiosInterceptor from '../../services/http/AxiosInterceptor'

// replace this to match your backend's error response body
export interface IErrorResponse {
  error: { message?: string }
}

const useAxios: ()=>AxiosInstance = () => {

  return AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL);
}

const client = AxiosInterceptor.addInterceptor(process.env.REACT_APP_API_BASE_URL);

const useData = <T>(
  url: string | null,
  method: Method,
  body?: any,
): [boolean, string | null, T | null] => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if(url) {

        // from https://beta.reactjs.org/learn/you-might-not-need-an-effect#fetching-data
        let ignore = false
    
        /**
         * used to abort an ongoing request, it will not work with Internet Explorer
         * it can be removed and use just the `ignore` variable
         */
        const controller = new AbortController()
    
        const fetchData = async () => {
          try {
            setLoading(true)
    
            const response = await client({
              url: url,
              method: method,
              data: body,
            })
    
            const data = response?.data?.data
    
            setError(null)
            if (!ignore) {
              setData(data)
            }
          } catch (err: any) {
            const error = err as Error | AxiosError<IErrorResponse>
            if (axios.isAxiosError(error)) {
              if (error.code === AxiosError.ERR_CANCELED) {
                return
              }
              if (!ignore) {
                setError(error?.response?.data?.message || error.message)
              }
            } else {
              if (!ignore) {
                setError(error.message)
              }
            }
          } finally {
            if (!ignore) {
              setLoading(false)
            }
          }
      }
      fetchData().then((r) => r)
  
      // To fix the race condition, we need to add a cleanup function to ignore stale responses:
      return () => {
        ignore = true
        controller.abort()
      }
    } else {
      setData(null);
      setError(null);
    }

  }, [url])

  return [loading, error, data]
}

export {
  useData, 
  useAxios
}
