/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react'
import classes from '../NewReservation.module.css'
import WizardStep from '../../../../components/common/wizard/WizardStep';
import { NewReserveDTO } from '../../../../model/dto-module';
import DataTable from '../../../../components/common/datatable/Datatable';
import { TableDetailCell, TableHeaderCell, WarehouseStockMovement } from '../../../../model/models-module';

type Props = {
  value: NewReserveDTO;
  sales: WarehouseStockMovement[];
  onAfterSubmit(values: any): void; 
  onAfterBack(): void; 
}

const NewReserveStepThree = ({value, sales, onAfterSubmit, onAfterBack}: Props) =>{

    const [dataShow, setDataShow] = useState<TableDetailCell[]>([]);
    const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([]);


    useMemo(async ()=>{

      setDataHeader([
        new TableHeaderCell({
          label: 'Almacén',
          key: 'warehouse.description',
          colStyle: {textAlign: 'center'},
          headerStyle: {textAlign: 'center', position: 'relative', right: '16px'}
        }),
        new TableHeaderCell({
          label: 'SKU',
          key: 'sku',
          colStyle: {textAlign: 'center'},
          headerStyle: {textAlign: 'center', position: 'relative', right: '10px'}
        }),
        new TableHeaderCell({
          label: 'Cantidad',
          key: 'stockMove',
          colStyle: {textAlign: 'center'},
          headerStyle: {textAlign: 'center', position: 'relative', right: '16px'}
        }),
      ])
      const aux = await sales.map(s=>new TableDetailCell(s));
      setDataShow(aux);

    }, [sales]);

    return (
      <WizardStep onNext={()=> { onAfterSubmit(value); }} onBack={()=>{onAfterBack()}} btnBackDisabled={false} btnNextDisabled={true} btnEndDisabled={false}>
        <div className={`${classes.formContainer} nwsn-scroll`}>
          {
          sales.length > 0 ?
          <DataTable headers={dataHeader}  rows={dataShow}
                  countItems={dataShow.length}  rowsPerPage={100} hasActions={false} groupActions={false}
                  appendOnScrollToBottom={true} onScrollToBottom={(pageSize: number)=>console.warn(pageSize)} ></DataTable>
          :
            <div style={{textAlign: 'center'}}>
              <h3>No hay ventas pendientes o pausadas.</h3>
            </div>
          }
        </div>
    </WizardStep>
            )
}


export default NewReserveStepThree;