/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useNavigate } from 'react-router-dom';
import Topbar from '../common/topbar/Topbar'

const NotAllowed = () =>{ 

    const navigate = useNavigate();

    return <Topbar title={'No tiene permisos! ⛔️'} backButton={true} onGoBack={()=>navigate('/stock')}>
    </Topbar>
}

export default NotAllowed
