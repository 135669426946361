/* eslint-disable @typescript-eslint/no-explicit-any */
import { NewReserveDTO } from '../model/dto-module';
import { WarehouseReservesTypeDistribution, } from '../model/models-module';
import BaseService from './BaseService';

export default class DistrubutionService extends BaseService {


    private static _instance: DistrubutionService;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    async findAllDistributionCentres() {
        
        try {    
            const response = await this.client({
                url: '/distribution-centres',
                method: 'GET'
            });

            return response.data.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
        
    }

    async findConfiguration(reserveDTO: NewReserveDTO): Promise<WarehouseReservesTypeDistribution[]>{
        
        try {
            const response = await this.client({
                url: `/warehouse-reserve-types-distribution/configuration/${reserveDTO.reserveType!.id}`,
                method: 'GET'
            });

            return response.data.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }

    }
    


}