/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseService from './BaseService';
import { CreateReservedStockDto } from '../model/dto-module';

export default class StockService extends BaseService {

    private static _instance: StockService;

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }
    

    async generateReport() {
      
        try {
        const response = await this.client({
            url: 'reserved-stocks/xlsx',
            method: 'GET',
            responseType: 'arraybuffer',
            });

            return response.data;

        } catch (err: any) {
            this.handleError(err);
            return null;
        }
    }

    async createStock(data: CreateReservedStockDto) {
        try {
        const response = await this.client({
            url: 'reserved-stocks',
            method: 'POST',
            data: data
            });

            return response.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
    }

    async updateStock({id, data}: {id?:number, data: any}) {
        try {
        const response = await this.client({
            url: `reserved-stocks/${id}`,
            method: 'PATCH',
            data: data
            });

            return response.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
    }

    async deleteStock(id:number) {
        try {
        const response = await this.client({
            url: `reserved-stocks/${id}`,
            method: 'DELETE',
            });

            return response.data;

        } catch (err: any) {
            this.handleError(err);
            throw err;
        }
    }

    async stockAvailable(organizationCode:string, sku:string) {
        try {
        const response = await this.client({
            url: `/stocks?organizationCode=${organizationCode}&sku=${sku}`,
            method: 'GET',
            });
            return Array.isArray(response.data.data) && response.data.data.length > 0 ? response.data.data[0].attributes : null;

        } catch (err: any) {
            this.handleError(err);
            return null;
        }
    }

    async allStocksByCenters(sku:string) {
        try {
        const response = await this.client({
            url: `/stocks/distribution-centres?sku=${sku}`,
            method: 'GET',
            });
            const centers = response.data.data;

            if(Array.isArray(centers)) {
                const map = new Map();
                centers.forEach(c=>{
                    map.set(c.attributes.organizationCode, c.attributes.availableToReserve);
                });

                return map;
            } else {
                return new Map();
            }


            // return Array.isArray(response.data.data) && response.data.data.length > 0 ? response.data.data[0].attributes : null;

        } catch (err: any) {
            this.handleError(err);
            return null;
        }
    }

    async stockByCenter(organizationCode:string, sku:string) {
        try {
        const response = await this.client({
            url: `/stocks?organizationCode=${organizationCode}&sku=${sku}`,
            method: 'GET',
            });
            return Array.isArray(response.data.data) && response.data.data.length > 0 ? response.data.data[0].attributes : null;

        } catch (err: any) {
            this.handleError(err);
            return null;
        }
    }

}