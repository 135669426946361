import { useEffect, useState } from 'react'
import DataTable from '../../components/common/datatable/Datatable'
import Topbar from '../../components/common/topbar/Topbar'
import { useData } from '../../components/hooks/useData'
import { TableAction, TableDetailCell, TableHeaderCell } from '../../model/models-module'
import { Outlet, useNavigate } from 'react-router-dom'
import WarehouseReserveTypeDistritubionService from '../../services/WarehouseReserveTypeDistributionService'
import classes from './Stock-distribution.module.css'
import { toast } from 'react-toastify'
import { Box, CircularProgress } from '@mui/material'
import NewsanIcons from '../../components/common/Icons'
import { Warehouse, WarehouseReserveTypeDistribution } from './interface'
import EditIcon from '@mui/icons-material/Edit'
import { GridDTO } from '../../model/dto-module'

const dataHeader: TableHeaderCell[] = [
  {
    label: 'Tipo reserva',
    key: 'reserveTypeDescription',
    sorteable: false,
    verticalAlign: 'middle',
  },
  { label: 'Almacén', key: 'warehouseDescription', sorteable: false },
  { label: 'Porcentaje', key: 'distribution', sorteable: false, colStyle: {textAlign: 'center'}, align: 'center' },
]

const StockDistribution = () => {
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([])
  const [dataTo, setDataTo] = useState<number>(20)
  const [dataOffset, setDataOffset] = useState<number>(0);

  const [stockDistributionEndpoint, setStockDistributionEndpoint] = useState<string>('')
  const [loadingStockDistribution, errorStockDistribution, dataStockDistribution] = useData<GridDTO<
    WarehouseReserveTypeDistribution>
  >(stockDistributionEndpoint, 'GET')
  const [refreshDatagrid, setRefreshDatagrid] = useState<boolean>(false)

  const [countItems, setCountItems] = useState<number>(0)
  const [page, setPage] = useState<number>(1)

  const navigate = useNavigate()

  const service: WarehouseReserveTypeDistritubionService =
    WarehouseReserveTypeDistritubionService.Instance

  function hasWarehouses(warehouses: Warehouse[]){
    let response = false
    warehouses.forEach((warehouse)=>{
        if (warehouse.status){
          response = true
        }
    })
    return response
  }

  useEffect(() => {
    setStockDistributionEndpoint(`/warehouse-reserve-types-distribution?limit=${dataTo}&offset=${dataOffset}`)

    if (!errorStockDistribution && dataStockDistribution) {
      setCountItems(dataStockDistribution.count)

      const data2: (TableDetailCell | undefined)[]  = dataStockDistribution.results.flatMap((reserveType, index) => {
         if (hasWarehouses(reserveType.warehouses)) return new TableDetailCell(
          {
            reserveTypeDescription: reserveType.description,
          },
          [
            new TableAction({
              label: 'Configurar',
              action: () => {
                navigate(`/stock-distribution/${reserveType.id}/config`)
                setRefreshDatagrid(true);
              },
              icon: (
                <span className={classes.editIcon}>
                  <EditIcon></EditIcon>
                </span>
              ),
            }),
            new TableAction({
              label: 'Eliminar',
              action: () => {
                service
                  .delete(reserveType.id)
                  .then(() => {
                    toast.success('Registro eliminado correctamente')
                    setRefreshDatagrid(true)
                  })
                  .catch((error) => {
                    toast.error('Error: ' + error)
                  })
              },
              icon: NewsanIcons.DELETE,
            }),
          ],
          new Map<string, React.ReactNode>([
            [
              'warehouseDescription',
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }} key={index}>
                {reserveType.warehouses.map((warehouse, index) => 
                warehouse.status ? (<Box key={index}>
                    {warehouse.reserveTypesDistribution.length === 0 ||
                    warehouse.reserveTypesDistribution[0].distribution === 0 ? (
                      <span className={classes.errorText}>{warehouse.description}</span>
                    ) : (
                      warehouse.description
                    )}
                  </Box>
                ): <></>)}
              </Box>,
            ],
            [
              'distribution',

              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }} key={index}>
                {reserveType.warehouses.map((warehouse, index) => 
                  warehouse.status ? (<Box key={index}>
                    {warehouse.reserveTypesDistribution.length === 0 ||
                    warehouse.reserveTypesDistribution[0].distribution === 0 ? (
                      <span className={classes.errorText}>0</span>
                    ) : (
                      warehouse.reserveTypesDistribution[0].distribution
                    )}
                  </Box>
                ): <></>)}
              </Box>,
            ],
          ]),
        )
      })
      setDataShow(data2.filter((item): item is TableDetailCell => item !== undefined))
    }
  }, [
    setDataShow,
    setCountItems,
    loadingStockDistribution,
    dataStockDistribution,
    stockDistributionEndpoint,
    dataOffset,
    dataTo
  ])

  useEffect(() => {
    if (refreshDatagrid) {
      setStockDistributionEndpoint('')
      setRefreshDatagrid(false)
    }
  }, [refreshDatagrid])

  function reloadTable(){
    setRefreshDatagrid(true)
  }

  return (
    <div className={classes.stockDistributionPage}>
      {loadingStockDistribution ?
        <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
      : null}
      <Topbar title={'Distribución de Stock'} subtitle={''} hasReloadIcon reloadPage={reloadTable}/>
      <div className={classes['container-abm-page']}>
        <div className={classes['table-templates-container']}>
          <DataTable
            headers={dataHeader}
            rows={dataShow}
            countItems={countItems}
            rowsPerPage={dataTo}
            hasActions={true}
            appendOnScrollToBottom={false}
            onPageSizeChange={(size) => {setDataTo(size); setDataOffset(0); setPage(1);}}
            onPageChange={(page: number)=>{ setPage(page); setDataOffset(page-1) }}
            pageSelected={page}
            pageable={true}
            selectable={false}
            className=' thinner stock-distribution-table'
          ></DataTable>
        </div>
      </div>
      <Outlet />
    </div>
  )
}

export default StockDistribution
