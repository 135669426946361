/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import React, { ReactNode, useState } from 'react';
import { TableAction } from '../../../model/models-module';

type Param = {
    actions: TableAction[];
    row: any; 
    index: number;
    group: boolean;
    children?: ReactNode;
    groupIcon?: any;
}

const ActionMenu = ({actions, row, index, group, groupIcon}: Param) => {

    const [anchorElActions, setAnchorElActions] = useState<null | HTMLElement>(null);

    const handleOpenActionMenu = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorElActions !== event.currentTarget) {
            setAnchorElActions(event.currentTarget);
        }
    }

    const handleCloseActionMenu = () => {
        setAnchorElActions(null);
    }

    return  group ?
                <>
                    <span className='action-column'>
                        <Tooltip title={'Acciones'}>
                            <IconButton onClick={handleOpenActionMenu} sx={{ p: 0 }}>

                                {groupIcon ?? <MoreVertOutlinedIcon />}
                            </IconButton>
                        </Tooltip>
                    </span>

                    <Menu
                        id={'menu-appbar'}
                        className='actions-menu'
                        anchorEl={anchorElActions}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        open={Boolean(anchorElActions)}
                        onClose={handleCloseActionMenu}
                        disableScrollLock
                    >
                        {
                            actions.map((act:TableAction) => (
                                <MenuItem key={`${act.label}${index}`} className={act.cssClass} disabled={!act.enable} onClick={() => {handleCloseActionMenu(); act.action(row.data, index);}}>
                                    <span className={'menu-icon'}>
                                        {act.icon}
                                    </span>
                                    <Typography textAlign='center'>{act.label}</Typography>
                                </MenuItem>
                                ))
                        }
                    </Menu>
                </>
            : 
            <>

                {
                actions.map((act:TableAction) => (
                    <span className={`actIcon ${act.cssClass} ${!act.enable ? 'actionDisabled' : ''}`} key={act.label} title={act.label} onClick={()=>{if(!act.enable) {return;} act.action(row.data, index)}}>
                        {act.icon}
                    </span>
                    ))
                }

            </>
    
};


export default ActionMenu;