import { useCallback, useEffect, useState } from 'react'
import DataTable from '../../components/common/datatable/Datatable'
import { useData } from '../../components/hooks/useData'
import { TableDetailCell } from '../../model/models-module'
import useModal from '../../components/hooks/useModal'
import Modal from '@mui/material/Modal'
import classes from './Stock-distribution.module.css'
import { useNavigate, useParams } from 'react-router-dom'
import InputFilter from '../../components/common/input-filter/InputFilter'
import { Box, Button, CircularProgress } from '@mui/material'
import WarehouseReserveTypeDistritubionService from '../../services/WarehouseReserveTypeDistributionService'
import { toast } from 'react-toastify'
import { WarehouseReserveTypeDistribution } from './interface'

const dataHeader = [
  { label: 'Tipo reserva', key: 'reserveTypeDescription', sorteable: false },
  { label: 'Wareohuse', key: 'warehouseDescription', sorteable: false },
  {
    label: 'Porcentaje',
    key: 'distribution',
    sorteable: false,
    align: 'center',
  },
]

const StockDistributionModal = () => {
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([])
  const { idReserveType } = useParams()

  const [countItems, setCountItems] = useState<number>(0)
  const { isOpen, toggle } = useModal()
  const [modalEndpoint, setModalEndpoint] = useState<string>('')
  const [loadingDatagrid, errorDatagrid, rowsDatagrid] = useData<
    WarehouseReserveTypeDistribution[]
  >(modalEndpoint, 'GET')
  const [dataToUpdate, setDataToUpdate] = useState<WarehouseReserveTypeDistribution[]>([])
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const [suma, setSuma] = useState<number>(0)
  const navigate = useNavigate()
  const service: WarehouseReserveTypeDistritubionService =
    WarehouseReserveTypeDistritubionService.Instance

  useEffect(() => {
    toggle()
  }, [])
  

  useEffect(() => {
    let cuenta = 0
    console.warn(dataToUpdate)
    if(dataToUpdate[0]?.warehouses){
      for (const item of dataToUpdate[0].warehouses) {
        cuenta += Number(item.reserveTypesDistribution[0]?.distribution)
      }
    }
    setSuma(cuenta)
  }, [dataToUpdate])

  useEffect(() => {
    idReserveType &&
      setModalEndpoint(`/warehouse-reserve-types-distribution/reserve-type/${idReserveType}`)

    if (!errorDatagrid && !loadingDatagrid && rowsDatagrid && idReserveType) {
      setCountItems(rowsDatagrid[0].warehouses.length)
      const data: TableDetailCell[] = rowsDatagrid.flatMap((reserveType) => {
         return reserveType?.warehouses?.map((warehouse, index) => {
          if (warehouse.status) return new TableDetailCell(
            {
              reserveTypeDescription: reserveType.description,
              warehouseDescription: warehouse.description,
              distribution:
                warehouse.reserveTypesDistribution.length === 0
                  ? 0
                  : warehouse.reserveTypesDistribution[0].distribution,
            },
            undefined,
            new Map<string, React.ReactNode>([
              [
                'distribution',
                <InputFilter
                  type='number'
                  debounceTime={1}
                  max={100}
                  min={0}
                  cssStyle={{
                    textAlign: 'center',
                    width: '17%',
                    margin: 'auto',
                    display: 'flex',
                    left: '18px'
                  }}
                  value={
                    warehouse.reserveTypesDistribution.length === 0
                      ? 0
                      : warehouse.reserveTypesDistribution[0].distribution
                  }
                  onChange={(e) => {
                    const datos: WarehouseReserveTypeDistribution[] = []

                    rowsDatagrid.map((item) => {
                      item.warehouses.forEach((w) => {
                        if (w.id === warehouse.id) {
                          if (w?.reserveTypesDistribution?.length > 0) {
                            w.reserveTypesDistribution[0].distribution = e
                          } else {
                            w.reserveTypesDistribution.push({ distribution: e })
                          }
                          datos.push(item)
                        }
                      })
                      setDataToUpdate(datos)
                    })

                    return e
                  }}
                  key={index}
                ></InputFilter>,
              ],
            ]),
          )
        })
      }).filter((item): item is TableDetailCell => item !== undefined)
      setDataShow(data)
    }
  }, [idReserveType, rowsDatagrid])

  const handleClose = useCallback(() => {
    toggle()
    navigate('/stock-distribution')
  }, [navigate])

  const handleConfirm = useCallback(async () => {
    setShowSpinner(true)
    let suma2 = 0

    for (const item of dataToUpdate[0].warehouses) {
      suma2 += Number(item.reserveTypesDistribution[0]?.distribution)
    }

    if (suma2 === 100) {
      for (const item of dataToUpdate[0].warehouses) {
        if (item.reserveTypesDistribution[0].id) {
          try {
            await service.update({
              id: item.reserveTypesDistribution[0].id,
              data: {
                idReserveType: dataToUpdate[0].id,
                idWarehouse: item.id,
                distribution: item.reserveTypesDistribution[0].distribution,
              },
            })
          } catch (error) {
            toast.error('Error en actualización')
          }
        } else {
          try {
            await service.create({
              idReserveType: dataToUpdate[0].id,
              idWarehouse: item.id,
              distribution: item.reserveTypesDistribution[0].distribution,
            })
          } catch (error) {
            toast.error('Error en creación')
          }
        }
      }
      setShowSpinner(false)
      toast.success('Distribución cargada correctamente')
      toggle()
      navigate('/stock-distribution')
    } else {
      toast.error('La suma de los porcentajes distribuidos debe ser igual a 100%')
      setShowSpinner(false)
    }
  }, [dataToUpdate, navigate])

  return (
    <>
      {showSpinner ? (
        <div className='loadingPage'>
          <CircularProgress className='circleProgress'/>
        </div>
      ) : null}
      <Modal open={isOpen} onClose={handleClose}>
        <div className={classes.modalContainer}>
          <span className={classes.modalTitle}>Distribución de Stock</span>

          <Box sx={{ backgroundColor: '#f5f5f5', width: '100%', padding: 2, borderRadius: '10px' }}>
            <DataTable
              headers={dataHeader}
              rows={dataShow}
              countItems={countItems}
              hasActions={false}
              appendOnScrollToBottom={false}
              pageable={false}
              selectable={false}
              className={classes['assign-stock-table']}
            ></DataTable>
          </Box>
          <div className={classes['modalButtonContainer']}>
            <Button onClick={handleClose} className='button-newsan-grey' variant='contained'>
              Cerrar
            </Button>
            <Button variant='contained' className='button-newsan-red' onClick={handleConfirm}>
              Confirmar
            </Button>
            <Button variant='contained' className={classes[`totalContainer-${suma == 100 ? 'green' : 'red'}`]}>
              Total: {suma} %
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default StockDistributionModal
