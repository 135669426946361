import { RouteType } from './config'
import HomePage from '../pages/home/HomePage'
import NewsanIcons from '../components/common/Icons'
import TuneIcon from '@mui/icons-material/Tune'
import StockLoadFile from '../pages/StockLoadFile/StockLoadFile'
// import AdminRolePage from '../pages/example3/AdminRolePage'
// import Settings from '../pages/settings/Settings'
// import Warehouse from '../pages/warehouse/Warehouse'
import StockView from '../pages/stock/Stock'
import AssignStock from '../pages/assign-stock/AssignStock'
import Stores from '../pages/stores/Stores'
import WarehousePage from '../pages/warehouse/Warehouse'
import SalesRate from '../pages/sales-rate/SalesRate'
import MovementReports from '../pages/movement-reports/MovementReports'
import ErrorsTransactionReport from '../pages/error-transaction-report/ErrorsTransactionReport'
import StockDistribution from '../pages/stock-distribution/StockDistribution'
import StockDistributionModal from '../pages/stock-distribution/StockDistributionModal'
import ReservedTypes from '../pages/reserved-types/ReservedTypes'
import NotificationsPage from '../pages/notifications/Notifications'

const appRoutes: RouteType[] = [
  {
    path: '/stock',
    element: <StockView />,
    state: 'Stock',
    sidebarProps: {
      displayText: 'Stock',
      icon: NewsanIcons.STOCK,
    },
    roles: ['admin', 'user'],
  },
  {
    path: '/stock/load-file',
    element: <StockLoadFile />,
    state: 'stock.load.field',
    sidebarProps: {
      displayText: 'Carga masiva',
      icon: NewsanIcons.MASSIVE_LOAD,
    },
    roles: ['admin', 'user'],
  },
  {
    path: '/stock-distribution',
    element: <StockDistribution />,
    state: 'stock.distribution',
    // child: [
    //   {
    //     path: '/stock-distribution/:idReserveType/config',
    //     element: <StockDistributionModal />,
    //     state: 'stock.distribution.config',
    //   },
    // ],
    
  },
  {
    path: '/stock-distribution/:idReserveType/config',
    element: <StockDistributionModal />,
    state: 'stock.distribution.config',
  },
  {
    path: '/stores',
    element: <Stores />,
    state: 'Stores',
    roles: ['admin'],
  },
  {
    index: false,
    path: '/stock/assign-file/:sku/:desc',
    element: <AssignStock />,
    state: 'stock.assign',
  },
   {
     path: '/storage',
     element: <WarehousePage />,
     state: 'storage',
     roles: ['admin'],
   },
   {
    path: '/reserved-types',
    element: <ReservedTypes />,
    state: 'reserved-types',
    roles: ['admin'],
  },
  {
    path: '/sale-rate',
    index: true,
    element: <SalesRate />,
    state: 'sales.rate',
    sidebarProps: {
      displayText: 'Tasa de venta',
      icon: NewsanIcons.ANALYSIS
    },
    roles: ['admin', 'user'],
  },
   {
    path: 'movement-report',
    element: <MovementReports />,
    state: 'movement-reports',
    roles: ['admin', 'user'],
   },
   {
    path: 'error-transaction-report',
    element: <ErrorsTransactionReport />,
    state: 'error-transaction-report',
    roles: ['admin', 'user'],
   },
   {
    path: 'notifications',
    element: <NotificationsPage></NotificationsPage>,
    state: 'notifications',
   },
   {
    element: <HomePage />,
    state: 'settings',
    sidebarProps: {
      displayText: 'Configuración',
      icon: NewsanIcons.SETTINGS
    },
    roles: ['admin'],
    child: [
      {
        path: '/storage',
        element: <WarehousePage />,
        state: 'storage',
        sidebarProps: {
          displayText: 'Almacenes',
          icon: NewsanIcons.WAREHOUSE
        },
        roles: ['admin'],
      },
      {
        path: '/stores',
        element: <Stores />,
        state: 'Stores',
        sidebarProps: {
          displayText: 'Tiendas',
          icon: NewsanIcons.STORE,
        },
        roles: ['admin'],
      },
      {
       path: '/reserved-types',
       element: <ReservedTypes />,
       state: 'reserved-types',
       sidebarProps: {
         displayText: 'Tipos de reserva',
         icon: NewsanIcons.BOOKMARKS
       },
       roles: ['admin'],
     },
     {
      path: '/stock-distribution',
      element: <StockDistribution />,
      state: 'stock.distribution',
      sidebarProps: {
        displayText: 'Distribución de stock',
        icon: <TuneIcon/>,
      },
      roles: ['admin'],
    },
    ]
   },
   {
    element: <HomePage />,
    state: 'reports',
    sidebarProps: {
      displayText: 'Monitores',
      icon: NewsanIcons.SUMMARIZE
    },
    roles: ['admin', 'user'],
    child: [
      {
        path: 'movement-report',
        element: <MovementReports />,
        state: 'movement-reports',
        sidebarProps: {
          displayText: 'Monitor de movimientos',
          icon: NewsanIcons.SUMMARY
        },
        roles: ['admin', 'user'],
       },
       {
        path: 'error-transaction-report',
        element: <ErrorsTransactionReport />,
        state: 'error-transaction-report',
        sidebarProps: {
          displayText: 'Monitor de errores de transacciones',
          icon: NewsanIcons.WARNING
        },
        roles: ['admin', 'user'],
       },
    ]
   }
  // {
  //   path: '/storage',
  //   element: <Warehouse />,
  //   state: 'storage',
  //   sidebarProps: {
  //     displayText: 'Almacén',
  //     icon: NewsanIcons.WAREHOUSE
  //   },
  //   roles: ['admin'],
  // },
  // {
  //   path: '/users',
  //   element: <AdminRolePage />,
  //   state: 'users',
  //   sidebarProps: {
  //     displayText: 'Usuarios',
  //     icon: NewsanIcons.USERS
  //   },
  //   roles: ['admin'],
  // },
  // {
  //   path: '/settings',
  //   element: <Settings />,
  //   state: 'settings',
  //   sidebarProps: {
  //     displayText: 'Tiendas',
  //     icon: NewsanIcons.STORE
  //   },
  //   roles: ['admin'],
  // },
  // {
  //   path: '/questions',
  //   element: <AdminRolePage />,
  //   state: 'questions',
  //   sidebarProps: {
  //     displayText: 'Preguntas',
  //     icon: NewsanIcons.QUESTIONS
  //   },
  //   roles: ['admin'],
  // },
  // {
  //   path: '/example2',
  //   element: <Example2PageLayout />,
  //   state: 'example2',
  //   sidebarProps: {
  //     displayText: 'Example 2',
  //     icon: <DashboardOutlinedIcon />,
  //   },
  //   child: [
  //     {
  //       index: true,
  //       element: <Example2Index />,
  //       state: 'example2.index',
  //     },
  //     {
  //       path: '/example2/child',
  //       element: <Example2ChildPage />,
  //       state: 'example2.child',
  //       sidebarProps: {
  //         displayText: 'Child',
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: '/example3',
  //   element: <AdminRolePage />,
  //   state: 'example3',
  //   sidebarProps: {
  //     displayText: 'Example 3',
  //     icon: <LockIcon />,
  //   },
  //   roles: ['admin'],
  // },
]

export default appRoutes
