/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// import useData from '../../components/hooks/useData'
import Topbar from '../../components/common/topbar/Topbar'
import { Box, Button, FormControl, MenuItem, Modal, Select, Tooltip, Typography } from '@mui/material'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import StockDataTable from '../../components/common/datatable/Datatable'
import NewsanIcons from '../../components/common/Icons'
import { useNavigate } from 'react-router-dom'
import { CodeDescription, FilterTable, FilterTableType, ReservedType, Stock, TableAction, TableDetailCell, TableHeaderCell, Warehouse } from '../../model/models-module'
import { addParam } from '../../utils/CommonUtils'
import {useData} from '../../components/hooks/useData'
import StockService from '../../services/StockService'
import CircularProgress from '@mui/material/CircularProgress';
import { GridDTO, StockDTO } from '../../model/dto-module'
import InputFilter from '../../components/common/input-filter/InputFilter'
import RefreshIcon from '@mui/icons-material/Refresh';
import classes from './Stock.module.css'
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { toast } from 'react-toastify'
import NewReservation from '../assign-stock/new-reservation/NewReservation'
import moment from 'moment'
import ButtonToCopy from '../../components/common/ButtonToCopy'


// const gridActions = 
const StockView = () => {
  const service: StockService = StockService.Instance;
  const [indexRowEdit, setIndexRowEdit] = useState<number>(-1);
  const [rowEdit, setRowEdit] = useState<Stock>();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [isCancelingClone, setIsCancelingClone] = useState<boolean>(false);
  const [refresGrid, setRefresGrid] = useState<boolean>(false);
  const [updateStockAvailable, setUpdateStockAvailable] = useState<boolean>(false);

  const [dataHeader, setDataHeader] = useState<TableHeaderCell[]>([]);
  const [dataTo, setDataTo] = useState<number>(0);
  const [dataOffset, setDataOffset] = useState<number>(0);
  const [sortArg, setSortArg] = useState<string>('');
  const [productCode, setProductCode] = useState<string>();
  const [productDescription, setProductDescription] = useState<string>();
  const [distributionCentreCode, setDistributionCentreCode] = useState<string>();
  const [reserveTypeCode, setReserveTypeCode] = useState<string>();
  const [warehouseCode, setWarehouseCode] = useState<string>();
  const [sort] = useState<Map<string, string>>(new Map());
  const [newSort, setNewSort] = useState<number>(0);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);


  const [headerActions, setHeaderActions] = useState<TableAction[]>(); 
  const [dataShow, setDataShow] = useState<TableDetailCell[]>([]); 
  const [appendRow, setAppendRow] = useState<any>(null);
  const [cloneData, setCloneData] = useState<boolean>(false);
  const navigate = useNavigate();
  const [mapOrigVal] = useState<Map<number, any>>(new Map());



  const [baseEndpoint, setBaseEndpoint] = useState<string>('/reserved-stocks');  
  const [endpoint, setEndPoint] = useState<string>('');  
  const [params] = useState<Map<string,string>>(new Map());  

  const [loadingReservedTypes, errorReservedTypes, dataGridReservedTypes] = useData<ReservedType[]>('reserved-stocks/reserved-types', 'GET');
  // const [loadingReservedTypes, errorReservedTypes, dataGridReservedTypes] = useData<CodeDescription[]>('reserved-types', 'GET');
  const [loadingDistributionCentres, errorDistributionCentres, dataGridDistributionCentres] = useData<CodeDescription[]>('distribution-centres', 'GET');
  const [loadingWarehouses, errorWarehouses, dataGridWarehouses] = useData<GridDTO<Warehouse>>('warehouses', 'GET');
  const [loading, error, dataGrid] = useData<GridDTO<Stock>>(endpoint, 'GET');
  const [dataEdit, setDataEdit] = useState<Stock|undefined>();
  const [dataClone, setDataClone] = useState<Stock|undefined>();
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [rowToDelete, setRowToDelete] = useState<Stock|undefined>();
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const editRow = useCallback((d: Stock)=> {
    setDataEdit(d);
  }, [ setDataEdit ]);

  const cloneRow = useCallback((d: Stock)=> {
    setDataClone(d);
  }, [ setDataClone ]);

  useEffect(()=>{
    if(dataEdit! || dataClone!) {
      setShowModal(true);
    }
  }, [dataEdit, dataClone]);

  if(error || errorReservedTypes || errorDistributionCentres || errorWarehouses) {
    console.error(error, errorReservedTypes, errorDistributionCentres, errorWarehouses);
  }


  const preventsEventsOnLoading = useCallback((e: any)=>{
    if(showSpinner) {
      e.preventDefault();
      e.stopPropagation();
      console.warn('no hace na');
    }
  }, [showSpinner]);

  useEffect(()=> {
    if(!initialized) {
      const isNotLoading = !loading && !loadingDistributionCentres && !loadingReservedTypes && !loadingWarehouses;
      const hasData = dataGrid != null && dataGridDistributionCentres != null && dataGridReservedTypes != null && dataGridWarehouses != null; 
      setInitialized(isNotLoading && hasData);
      setDataTo(10);
    }
  }, [loading, loadingDistributionCentres, loadingReservedTypes, loadingWarehouses]);


  useEffect(()=> {
    addParam(dataTo, 'limit', params, baseEndpoint, setEndPoint);
  }, [dataTo, baseEndpoint])


  useEffect(()=> {
    if(initialized) {
      addParam(dataOffset, 'offset', params, baseEndpoint, setEndPoint);
    }
  }, [dataOffset, baseEndpoint])


  useEffect(()=> {
    if(initialized) {
      setDataOffset(0)
      setDataShow([]);
      addParam(sortArg, 'sort', params, baseEndpoint, setEndPoint);
    }
  }, [sortArg, baseEndpoint])
  
  useEffect(()=> {
    if(initialized) {
      setDataOffset(0)
      setDataShow([]);
      addParam(productCode, 'productCode', params, baseEndpoint, setEndPoint);
    }
    
  }, [productCode, baseEndpoint]);

  useEffect(()=> {
    if(initialized) {
      setDataOffset(0)
      setDataShow([]);
      addParam(productDescription, 'productDescription', params, baseEndpoint, setEndPoint);
    }
    
  }, [productDescription, baseEndpoint]);

  useEffect(()=> {
    if(initialized) {
      setDataOffset(0)
      setDataShow([]);
      addParam(reserveTypeCode, 'reserveTypeCode', params, baseEndpoint, setEndPoint);
    }
    
  }, [reserveTypeCode, baseEndpoint]);

  useEffect(()=> {
    if(initialized){
      setDataOffset(0)
      setDataShow([]);
      addParam(distributionCentreCode, 'distributionCentreCode', params, baseEndpoint, setEndPoint);
    }
  }, [distributionCentreCode, baseEndpoint]);

  useEffect(()=> {
    if(initialized){
      setDataOffset(0)
      setDataShow([]);
      addParam(warehouseCode, 'warehouseCode', params, baseEndpoint, setEndPoint);
    }
  }, [warehouseCode, baseEndpoint]);

  useEffect(()=>{
    if(refresGrid) {
      let aux = '';
      params.forEach((valueArg) => {
        if(aux) {
          aux += `&${valueArg}`;
        } else {
          aux += `?${valueArg}`;
        }
      });
      setEndPoint(baseEndpoint + aux);
    }
  }, [refresGrid, baseEndpoint, params]);


  useEffect(()=>{
    if(newSort > 0) {

      let args = '';
      sort.forEach((value)=>{
        if(args === '') {
          args += value;
        } else {
          args += `,${value}`;
        }
      });
      setSortArg(args);
      setNewSort(0);
    }
  }, [newSort, setSortArg, setNewSort]);

  const onSort = (key: string, order: string)=>{ 
    if(order === ''){ 
      sort.delete(key)
    } else {
      sort.set(key, `${key}:${order}`);
    }
    setNewSort(1);
  };

  useEffect(()=> {
    let filterLoadeed = !loadingReservedTypes && dataGridReservedTypes != null &&  dataHeader.length === 0;
    filterLoadeed = filterLoadeed && !loadingDistributionCentres && dataGridDistributionCentres != null;
    filterLoadeed = filterLoadeed && !loadingWarehouses && dataGridWarehouses != null;

  if(filterLoadeed ) {
    const mapReservedTypes = new Map();
    setDataHeader([
      new TableHeaderCell({label: 'CD', key: 'distributionCentreCode', sorteable: true, filter: new FilterTable({onChange: setDistributionCentreCode, useCodeField:true, data: dataGridDistributionCentres ?? [], type: FilterTableType.MULTIPLE}), headerStyle:{maxWidth: '60px'}, colStyle:{position:'relative', top: '5px'}}), 
      new TableHeaderCell({label: 'SKU', key: 'productCode', sorteable: true, filter: new FilterTable({onChange: (newVal: any)=>{ setProductCode(newVal)}, placeholder: 'Busca aqui'  }), colStyle:{position:'relative', top: '5px', maxWidht: '170px'}, headerStyle: {width: '170px'}}), 
      new TableHeaderCell({label: 'Descripción', key: 'productDescription', sorteable: true, filter: new FilterTable({onChange: (newVal: any)=>{ setProductDescription(newVal)}, placeholder: 'Busca aqui'  }), colStyle:{position:'relative', top: '5px', width: '245px'}, headerStyle: {width: '280px'}}), 
      new TableHeaderCell({label: 'Tipo reserva', key: 'reserveTypeDescription', sorteable: true, filter: new FilterTable({onChange: (newVal: any)=>{ setReserveTypeCode(newVal)}, useCodeField:true, data: 
      dataGridReservedTypes! ? 
      
      dataGridReservedTypes.map((rt)=>{
        if(mapReservedTypes.has(rt.id)) {
          return new CodeDescription(-1);
        }
        mapReservedTypes.set(rt.id, rt);
        rt.code = rt.reservedTypeCode;
        return new CodeDescription(rt.id,rt.code, rt.description );
      }).filter(a=>a.id>0) ?? [] 
      : []

      , type: FilterTableType.MULTIPLE}), colStyle:{position:'relative', top: '5px', whiteSpace: 'nowrap'}, headerStyle: {minWidth: '140px'}}),
      new TableHeaderCell({label: 'Almacén', key: 'warehouseCode', sorteable: true, filter: new FilterTable({onChange: setWarehouseCode, useCodeField:true, data: dataGridWarehouses?.results ?? [], type: FilterTableType.MULTIPLE}), headerStyle:{minWidth: '80px'}, colStyle:{position:'relative', top: '5px'}}),
      new TableHeaderCell({label: 'Disponible', key: 'stockAvailable', sorteable: true, headerStyle: {minWidth: '130px', textAlign: 'center'}, colStyle: {textAlign: 'center', position: 'relative', top: '5.25px', justifyContent: 'center'}}),
      new TableHeaderCell({label: 'Reserva', key: 'stockReserved', sorteable: true, colStyle: { textAlign: 'center', position: 'relative', top: '5.25px'}}),
      // new TableHeaderCell({label: 'Mensaje', key: 'message', sorteable: false}),
    ]);
  }
  })
  

  useEffect(()=>{
    if(!loading) {
      if(appendRow === null) {
        return;
      }
      const r: StockDTO = new StockDTO();
      if(cloneData) {
        r.distributionCentreCode = appendRow.row.distributionCentreCode;
        r.productCode = appendRow.row.productCode;
        r.reserveTypeCode = appendRow.row.reserveTypeCode;
        r.warehouseCode = appendRow.row.warehouseCode;
      } else {
        r.distributionCentreCode = '';
        r.productCode = '';
        r.reserveTypeCode = '';
        r.warehouseCode = '';
      }

      const createStock = ( row: StockDTO ) => {
          let withError = true;
          setShowSpinner(true);
          const dataBody = Object.assign({}, row);

          if(row.stockAvailable) {
            delete dataBody.stockAvailable;


            service.createStock(dataBody)
            .then(()=>{
              withError = false;
              toast.success('Reserva registrada');
            })
            .catch(
              (w)=>{
                console.error(w);
                toast.error('Error al intentar Agregar Reserva.');
              }
            )
            .finally(
              () => {
                if(!withError) {
                  setDataOffset(0);
                  setDataTo(10);
                  setEndPoint('');
                }
                setRefresGrid(true);
                setShowSpinner(false)
              }
            )
          } else {
            setShowSpinner(false);
          }
      }

      const copyRow = Object.assign({}, new TableDetailCell( r, [
          new TableAction({label: 'Guardar' , action: (row:StockDTO)=> {
            if(row) {
              createStock(row)
            }
          } , icon: NewsanIcons.PLUS}),
          new TableAction({label: 'Cancelar' , action: ()=> { cancelClone( appendRow.index+1)} , icon: <span className={classes.errorButton}>{NewsanIcons.CANCEL_ERROR}</span> }),
        ], 
        new Map<string, React.ReactNode>([
            ['distributionCentreCode', 
            
                <FormControl key={'select-centre'} className='select-opt' size="small">
                  <Select defaultValue={r.distributionCentreCode} onChange={(e)=>{
                    r.distributionCentreCode = e.target.value;
                     setRowEdit(r); setIndexRowEdit(appendRow.index+1); setUpdateStockAvailable(true);
                  }
                    }>
                      {
                        (dataGridDistributionCentres ?? []).map((d, i)=><MenuItem className='select-option' key={`opt-centre-${i}`} value={d.code}>{d.description}</MenuItem>)
                      }
                  </Select>
                </FormControl>
          ],
          ['productCode',
          <InputFilter debounceTime={1} value={r.productCode} onChange={(e)=>{ r!.productCode = e; setRowEdit(r); setIndexRowEdit(appendRow.index+1); setUpdateStockAvailable(true) }} key={`custom-${appendRow.index+1}`}></InputFilter>
        ],
        ['reserveTypeDescription', 
        
            <FormControl key={'select-type'} className='select-opt' size="small">
              <Select defaultValue={r.reserveTypeCode} onChange={(e)=>{
                r.reserveTypeCode = e.target.value;
                const data = dataGridReservedTypes?.find(rt=>rt.reservedTypeCode === e.target.value);
                r.warehouseCode = data?.warehouseCode;
                setRowEdit(r); setIndexRowEdit(appendRow.index+1); setUpdateStockAvailable(true);
              }
                }>
                  {
                    (dataGridReservedTypes ?? []).map((d, i)=><MenuItem className='select-option' key={`opt-centre-${i}`} value={d.reservedTypeCode}>{d.description}</MenuItem>)
                  }
              </Select>
            </FormControl>
          ],
          ])
      ));
      let aux: any[] = Object.assign([], dataShow); 
      const listPrev = aux.slice(0, appendRow.index+1);
      const listPost = aux.slice(appendRow.index+1);
      listPrev.push(copyRow);
      aux = listPrev.concat(listPost);
      setAppendRow(null);
      setCloneData(false);
      setDataShow(aux);
    }

  }, [appendRow, loading, dataGridDistributionCentres, dataGridReservedTypes]);


  useEffect(()=>{

    setShowSpinner(loading);
    if(!loading && dataGrid) {
      let aux: TableDetailCell[] = Object.assign([], dataOffset > 0 ? dataShow : []); 
      aux = aux.concat(dataGrid.results.map(s=>new Stock(s)).map((r:any, i: number)=>{
        mapOrigVal.set(r.id, r.stockReserved);
        return createRow(r, (aux.length + i)); // Sumo el indice de la lista nueva a la longitud de la lista existente
      }));
      setDataShow(aux);
      setRefresGrid(false);

    }
  }, [setDataShow, dataGrid, setAppendRow, loading]);

  useEffect(()=>{
    let indexInData: number|null = null;
    if(rowEdit) {
      indexInData = dataShow.findIndex(ds=>ds.data.id=== rowEdit.id);
    }

    if(updateStockAvailable && indexRowEdit >= 0) {
      const aux :TableDetailCell[] =  Array.from(dataShow);
      
      const auxRow =  Object.assign({}, aux[indexInData ?? indexRowEdit])
      const pDistributionCode = rowEdit?.distributionCentreCode ?? '';
      const pProductCode = rowEdit?.productCode ?? '';
      if(pDistributionCode && pProductCode) {
        service.stockAvailable(rowEdit?.distributionCentreCode ?? '', rowEdit?.productCode ?? '')
        .then(
          (resp)=> {
            if(resp) {
              auxRow.data.productDescription = resp.description;
              auxRow.data.stockAvailable = resp.availableToReserve;
            } else {
              auxRow.data.productDescription = null;
              auxRow.data.stockAvailable = null;
              toast.error('El artículo no está disponible para el centro de distribución');              
            }
          }
        )
        .finally(
          ()=> {
              auxRow.customDefinitions!.delete('stockAvailable');
              aux[indexInData ?? indexRowEdit] = auxRow;
              setDataShow([]);
              setDataShow(aux);
              setUpdateStockAvailable(false);
              setShowSpinner(false);
  
          }
        )
      } else {
        setDataShow([]);
        setDataShow(aux);
        setUpdateStockAvailable(false);
        setShowSpinner(false);
      }

    }

    if(isCancelingClone && indexRowEdit >= 0) {
      const aux :TableDetailCell[] = Array.from(dataShow);
      aux.splice(indexRowEdit, 1);
      
      setDataShow([]);
      setDataShow(aux);
      setIsCancelingClone(false);
    }


  }, [indexRowEdit, rowEdit, isCancelingClone, updateStockAvailable]);

  const cancelClone = (i: number) => {
    setIsCancelingClone(true);
    setIndexRowEdit(i);
  }

  const createRow = (r:Stock, i: number) => {
    return new TableDetailCell(r,
          (r.id != undefined && r.id === 0) ? 
          [
          ] : 
          [
            new TableAction({label: 'Asignar stock' ,
            action: (row:Stock)=> {
              navigate(`/stock/assign-file/${encodeURIComponent(row.productCode ?? '')}/${encodeURIComponent(row.productDescription ?? '')}`);
            } , icon: NewsanIcons.PLUS}),
            new TableAction({label: 'Clonar stock' ,
            action: (row:any)=> {
              cloneRow(row);
            }
            ,icon: NewsanIcons.CLONE}),
            new TableAction({label: 'Editar' ,
            enable:r.pendingDate === null,
            action: (row:any)=> {
              editRow(row);
            }
            ,icon: NewsanIcons.CLONE}),
            new TableAction({label: 'Refrescar stock' , action: (row:any, index:number)=> {
              setShowSpinner(true);
              setRowEdit(row); setIndexRowEdit(index); setUpdateStockAvailable(true);
            } , icon: NewsanIcons.REFRESH}),
            new TableAction({label: 'Eliminar stock' ,
              enable:r.pendingDate === null,
              action: (row:Stock)=> {
                setRowToDelete(row)
                setOpenDeleteModal(true)
            } , icon: NewsanIcons.DELETE}),
          ],
          new Map<string, React.ReactNode>([
            ['stockAvailable',
            (r.id != undefined && r.id === 0) ? '-' :
            <RefreshIcon style={{cursor: 'pointer', position:'relative', bottom:'2px'}} className={classes.refreshIconCss} key={`stock-${i}`} onClick={()=>{
              setShowSpinner(true);
              setRowEdit(r); setIndexRowEdit(i); setUpdateStockAvailable(true);
            }}></RefreshIcon>],
          //   ['stockReserved', 
          //   <div key={`container-${i}`} className={classes.customElement}>

          //     <InputFilter
          //     inputDisabled={(r.id != undefined && r.id === 0)}
          //     type='number' 
          //     debounceTime={1}
          //     cssStyle={{textAlign: 'center'}}
          //     value={r.stockReserved} 
          //     onFocus={()=>{
          //       setUpdateRowToPersist(false);
          //     }}
          //     onChange={(e: string)=>{ 
          //       r!.stockReserved = Number(e ?? 0); 
          //       if(r.id! != 0 && mapOrigVal.has(r.id!) && mapOrigVal.get(r.id!).toString() !== r!.stockReserved.toString()) {
          //         setIdRowToPersist(r.id!);
          //       }
          //     }} 
          //     onBlur={()=>setUpdateRowToPersist(true)}
          //     onEnter={()=>setUpdateRowToPersist(true)}
          //     key={`custom-${i}`}></InputFilter>
          //     {
          //       addCheck ?
          //         <span key={`cont-icon-${i}`} className={classes.checkButton}> <CheckCircleOutlineRoundedIcon key={`check-icon-${i}`}></CheckCircleOutlineRoundedIcon> </span>
          //       : null
          //     }
          //   </div>
          // ],

        ['stockReserved',

        <div key={`container-${i}`} className={r.pendingDate! ? classes.reservedWithPendingDate : ''}>
          <span className={r.pendingDate! ? classes.reserveCellData : ''}>{r.stockReserved}</span> 
          {
            r.pendingDate! ?
            <Tooltip title={`Posee una ${r.pendingMoveType} por la cantidad de ${r.pendingQty} \n\rrealizado la fecha ${moment(r.pendingDate).format('DD/MM/YYYY')}`}>
              <i onClick={()=>navigate(`/movement-report?transactionId=${r.pendingTransactionId}`)}><WarningAmberIcon className={classes.warningIcon}></WarningAmberIcon></i> 
            </Tooltip>
            : null
          }
        </div>
        
        ],
        ['productCode',
        <div key={r.productCode} className={classes['textWithCopy']} style={{minWidth: '105px'}}>
            <Typography fontSize={'0.875rem'} whiteSpace={'nowrap'}>{r.productCode}</Typography>
            {r.productCode != null ?<ButtonToCopy text={r.productCode} style={{position: 'relative', bottom: '6px'}}></ButtonToCopy>: null}
          </div>
        ],
        ['productDescription',

          <div key={r.productCode} className={classes['textWithCopy']} style={{width: '260px'}}>
            {r.productDescription != null && r.productDescription.length < 40 ? <Typography sx={{width: '225px'}} className={classes.productDescription} fontSize={'0.875rem'}>{r.productDescription}</Typography> : <Tooltip title={r.productDescription}><Typography sx={{width: '225px'}} className={classes.productDescription} fontSize={'0.875rem'}>{r.productDescription}</Typography></Tooltip>}
            {r.productDescription != null ?<ButtonToCopy text={r.productDescription} style={{position: 'relative', bottom: '6px'}}></ButtonToCopy>: null}

          </div>
        ],
        // ['stockReserved',
        // <span key={`stockReserved-${r.id}`} style={{ display: 'inline-flex', alignItems: 'center' }}>
        //   <span key={`stockReserved-text-${r.id}`} style={{    textAlign: 'center', width: '65%'}}>
        //     {r.stockReserved}
        //   </span>
          
        // </span>
        // ],
          ]), (r.id != undefined && r.id === 0) ? classes.redRow : classes.simpleRow
          );
  }

  function reloadTable() {
    setEndPoint('')
    setRefresGrid(true);
  }

  useEffect(() => {
    if(baseEndpoint == '') setBaseEndpoint('/reserved-stocks'); 
  }, [baseEndpoint])

  useMemo(()=>{
    const reportAct = new TableAction({label:'Generar Reporte', action: () => {
            setShowSpinner(true);
            service.generateReport().then(
              (data: any)=>{
                const blob = new Blob([data]);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'reporte.xlsx';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              }
            ).finally(
              () => {
                setShowSpinner(false);
              }
            );
          }, icon: <FileDownloadOutlinedIcon></FileDownloadOutlinedIcon> });
    const addStockAct = new TableAction({label:'Agregar Reserva', action: () => {
            setShowModal(true);
          }, icon: NewsanIcons.ADD_OUTLINED});

    setHeaderActions([addStockAct, reportAct]);
  }, [])
  

  return (
    <>
    {
    showSpinner ?
      <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
      : null
    }
      <Topbar title={'Gestión de Stock'} subtitle={''} hasReloadIcon reloadPage={reloadTable} />
      <div className={classes.stockDataTable} onClick={preventsEventsOnLoading} onPointerEnter={preventsEventsOnLoading} onBlur={preventsEventsOnLoading} onKeyDown={preventsEventsOnLoading} onKeyDownCapture={preventsEventsOnLoading}>
          <StockDataTable headers={dataHeader} rows={refresGrid ? [] : dataShow} onSortChange={onSort} headerGroupActionsIcon={NewsanIcons.PLUS}
          countItems={dataGrid ? dataGrid.count : 0}  rowsPerPage={10} hasActions={true} headerActions={headerActions}
          appendOnScrollToBottom={true} onScrollToBottom={(pageSize: number)=>{ setShowSpinner(true); setEndPoint(''); setDataOffset(Math.ceil(pageSize / 10) - 1);}} 
          >
          </StockDataTable>
      </div>

    {
      showModal ?
      <NewReservation 
      open={showModal}
      data={dataEdit ?? dataClone}
      editMode={dataEdit !== undefined}
      fixedSku={false}
      showSkuDesc={true}
      onClose={(e, reason)=>{
      console.warn(e);

      if (reason && reason === 'backdropClick') 
          return;
      
      setShowModal(false);
      setDataEdit(undefined);
      setDataClone(undefined);

      if (reason && reason === 'confirm')  {
        reloadTable();
      }
    }}
      />
      :
      null
    }
    <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          borderRadius: 4,
          boxShadow: 24,
          p: 3
        }}>
          {
    showSpinner ?
      <div className='loadingPage'>
        <CircularProgress className='circleProgress'/>
      </div>
      : 
        <>
          <Typography id="modal-modal-title" >
            ¿Estás seguro que quieres eliminar stock?
          </Typography>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" className={classes['buttonDeleteCancel']} onClick={handleCloseDeleteModal}>
              Cancelar
            </Button>
            <Button variant="contained" className={classes['buttonDelete']} onClick={() => {
              if (rowToDelete) {
                setShowSpinner(true);
                handleCloseDeleteModal()
                service.deleteStock(rowToDelete.id!).catch((e) => {console.error(e)}).finally(
                  () => {
                    setDataOffset(0);
                    setDataTo(10);
                    setEndPoint('');
                    setRefresGrid(true);
                    setShowSpinner(false)
                    setRowToDelete(undefined)
                  }
                );
              }
            }}>
              Sí, eliminar
            </Button>
          </Box>
        </>
      }
        </Box>
      </Modal>
      {/* {
        initialized && dataGrid !== null ?
      :
      <>Cargando..</>
      } */}
    </>
  )
}

export default StockView;
